import React from 'react';
import {
    Route
} from "react-router-dom";
import Header from "../../components/Header";
import ContainerPage from "../../components/ContainerPage";
import UserRoutes from "./../UserRoutes";
import Login from "../../pages/Login";
import {routes as R} from "../../constants/routes";
import AlertWarning from "../../components/AlertWarning";
import Support from "../../pages/Support";
import Store from "../../pages/Store";
import Checkout from "../../pages/Checkout";
import Cart from "../../pages/Cart";
import TermsOfService from "../../pages/TermsOfService";
import PrivacyPolicy from "../../pages/PrivacyPolicy";

const placeholderMessage = 'Updates coming soon';

const CommonRoutes = (props) => (
    <>
        <ContainerPage>
            <Route exact path={R.LOGIN} component={Login}/>
            <Route exact path={R.HELP_CENTER} component={Support}/>
            <Route exact path={R.STORE} component={Store}/>
            <Route exact path={R.STORE_CART} component={Cart}/>
            <Route exact path={R.STORE_CHECKOUT} component={Checkout}/>
            <Route exact path={R.TERMS_OF_SERVICE}>
                <TermsOfService/>
            </Route>
            <Route exact path={R.PRIVACY_POLICY}>
                <PrivacyPolicy/>
            </Route>
            <Route path={R.HOME} component={UserRoutes}/>
        </ContainerPage>
    </>
);

export default CommonRoutes;
