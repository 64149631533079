import {actions as A} from "../constants/actions";

const defaultState = {
    settings: null,
    loading: true,
    error: false,
    errorMessage: ''
};

export default (state = defaultState, {type, payload}) => {
    switch(type) {
        case A.GET_SETTINGS_BY_STATION_ID:
            return {...state, loading: true};

        case A.GET_SETTINGS_BY_STATION_ID_SUCCESS:
            const {settings} = payload;
            return {...state, settings, loading: false};

        case A.GET_SETTINGS_BY_STATION_ID_FAILURE:
            const {errorMessage} = payload;
            return {...state, errorMessage, error: true, loading: false};

        case A.SET_SETTINGS_FOR_STATION_ID:
            return {...state};

        case A.SET_SETTINGS_FOR_STATION_ID_SUCCESS:
            return {...state, loading: false};

        case A.SET_SETTINGS_FOR_STATION_ID_FAILURE:
            const {errorMessage: err} = payload;
            return {...state, errorMessage: err, error: true, loading: false};

        case A.LOG_OUT:
            return {...defaultState};

        default:
            return state;
    }
}
