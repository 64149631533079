import React, {useState} from 'react';
import PropTypes from 'prop-types';
import StoreView from './Store-view';
import {
    defaultImagesAnalytics,
    defaultImagesKiosk
} from '../../mockData/products';
import stripeConfig from '../../config/stripe';

const Store = ({user, products}) => {
    const normalizeTestProduct = product => {
        const {
            id: originalId,
            imageSrc: originalImageSrc,
            displayed_price,
            require_user
        } = product;
        const requireUser = Boolean(require_user);
        const prices = {USD: displayed_price};
        let id = originalId;
        let imageSrc = originalImageSrc;

        if (product.name === "Trace Check-In Analytics") {
            id = 'price_1HwpKjFW5w8GcFj4yO8UdvKR';
            if (product.imageSrc.length === 0) {
                imageSrc = defaultImagesAnalytics;
            }
        }
        if (product.name === "Trace Check-In Kiosk") {
            id = 'price_1HxbyAFW5w8GcFj4WUMgqAnH';
            if (product.imageSrc.length === 0) {
                imageSrc = defaultImagesKiosk;
            }
        }

        return {
            ...product,
            imageSrc,
            requireUser,
            prices,
            id
        }
    };
    const normalizeLiveProduct = product => {
        const {
            displayed_price,
            require_user,
            free_shipping
        } = product;
        const requireUser = Boolean(require_user);
        const prices = {USD: displayed_price};
        const freeShipping = free_shipping === 'yes';

        return {
            ...product,
            requireUser,
            prices,
            freeShipping
        }
    };
    const sortBySortOrder = (prev, current) => {
        const [prevSortOrder, currentSortOrder] = [prev, current].map(item => parseInt(item.sort_order));
        if (prevSortOrder > currentSortOrder) return 1;
        if (prevSortOrder < currentSortOrder) return -1;
        if (prevSortOrder === currentSortOrder) return 0;
    };
    const testProducts = products.filter(item => item.id === null);
    const normalizedTestProducts = testProducts
        .map(normalizeTestProduct)
        .sort(sortBySortOrder);

    const liveProducts = products.filter(item => item.id !== null);
    const normalizedLiveProducts = liveProducts
        .map(normalizeLiveProduct)
        /*exclude some weird test (?) products*/
        .filter(item => ['prod_IQ82EFHsrz6pCM', 'prod_IYjOoj0ocUr4Tu'].indexOf(item.prodId) === -1)
        .sort(sortBySortOrder);

    const normalizedProducts = (stripeConfig.mode === 'live')
        ? normalizedLiveProducts
        : normalizedTestProducts;

    const [selected, setSelected] = useState(normalizedProducts[0]);
    const selectById = id => {
        const selected = normalizedProducts.filter(item => item.id === id);
        setSelected(selected[0]);
    };

    return (
        <StoreView
            products={normalizedProducts}
            selected={selected}
            selectProduct={selectById}
            userIsLogged={Boolean(user)}
        />
    )
};

Store.propTypes = {
    user: PropTypes.object,
    products: PropTypes.array,
};

export default Store;
