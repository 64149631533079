import React from "react";
import PropTypes from "prop-types";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {NavLink, useLocation} from "react-router-dom";
import {routes as R} from "../../constants/routes";
import MenuDefault from "./MenuDefault";
import MenuTransparentBg from "./MenuTransparentBg";
import styles from "./styles.module.scss";

const Header = ({user, variant = 'default'}) => {
    const location = useLocation();
    const isWhiteBg = location.pathname !== R.HOME;
    console.log({isWhiteBg});
    const isDefault = variant === 'default';
    const isTransparentBg = variant === 'transparent-bg';
    return (
        <header className={styles[variant]}>
            <Navbar
                collapseOnSelect={true}
                expand="lg"
                bg="primary"
                variant="primary"
            >
                <NavLink
                    to={R.HOME}
                    className="navbar-brand"
                >
                    Trace Check-In
                </NavLink>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav" className={isWhiteBg && 'white-bg'}>
                    <Nav className={"w-100"}>
                        <div className={`nav-link ${styles.disabledLink}`}>
                            Analytics
                        </div>
                        {/*<NavLink
                            to={R.LOGS}
                            className="nav-link"
                            disabled
                        >
                            Analytics
                        </NavLink>*/}
                        <NavLink
                            to={R.REGISTRATION}
                            className="nav-link"
                        >
                            Register
                        </NavLink>
                        <NavLink
                            to={R.STORE}
                            className="nav-link"
                        >
                            Store
                        </NavLink>
                        <NavLink
                            to={R.HELP_CENTER}
                            className="nav-link"
                        >
                            Help Center
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
                {isDefault && <MenuDefault isLogged={Boolean(user)}/>}
                {isTransparentBg && <MenuTransparentBg isLogged={Boolean(user)}/>}
            </Navbar>
        </header>
    )
};

Header.propTypes = {
    user: PropTypes.object,
    variant: PropTypes.oneOf(['default', 'transparent-bg'])
};

export default Header;
