import {functions} from "../../lib/firebase";
import {functions as F} from "../../constants/functions";
import {actions as A} from "../../constants/actions";

export const createPortalSession = ({customerId}) => {
    return (dispatch) => {
        dispatch({
            type: A.CREATE_PORTAL_SESSION
        });
        const createPortalSession = functions.httpsCallable(F.CREATE_PORTAL_SESSION);
        return createPortalSession({customerId, returnUrl: window.location.href})
            .then(response => {
                const {url} = response.data;
                dispatch({
                    type: A.CREATE_PORTAL_SESSION_SUCCESS,
                    payload: {url}
                });

                return url
            })
            .catch((err) => {
                return dispatch({
                    type: A.CREATE_PORTAL_SESSION_FAILURE,
                    payload: {errorMessage: err.message}
                })
            });
    };
};
