import React from 'react';
import arrow from '../../../assets/landing/arrow-big.svg';
import screen1 from '../../../assets/landing/screen1.png';
import screen2 from '../../../assets/landing/screen2.png';
import styles from './styles.module.scss';

const header = 'Just takes a few seconds';
const text = 'The Trace Check-In experience takes only a few seconds as you enter a space, giving users a sense of security within the workplace.';

const Timing = () => (
    <section className={styles.timingContainer}>
        <div className={styles.contentFrame}>
            <h2>{header}</h2>
            <p>{text}</p>
            <div className={styles.row}>
                <img src={screen1} alt="screen1" className={styles.screenImg}/>
                <img src={arrow} alt="arrow"/>
                <img src={screen2} alt="screen2" className={styles.screenImg}/>
            </div>
        </div>
    </section>
);

export default Timing;
