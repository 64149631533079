import {connect} from "react-redux";
import FormStationSettings from "./FormStationSettings";
import {setSettingsForStationId} from "../../actions/setSettingsForStationId";
import {getSettingsByStationId} from "../../actions/getSettingsByStationId";

const mapStateToProps = ({
    settings: {settings, loading, error, errorMessage},
    station: {stationId}
                         }) => ({
    settings,
    loading,
    error,
    errorMessage,
    stationId
});

export default connect(
    mapStateToProps,
    {
        setSettingsForStationId,
        getSettingsByStationId
    }
)(FormStationSettings);
