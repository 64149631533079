import {functions} from "../lib/firebase";
import {functions as F} from "../constants/functions";

//TODO: make this function a redux action
export const registerStationByCode = async ({code, owner}) => {
    const activateStation = functions.httpsCallable(F.REGISTER_STATION);
    let ok, error;
    try {
        const result = await activateStation({code, owner});
        ok = result.data.ok;
    } catch (e) {
        error = e.message;
    }

    return {error, ok};
};
