import {functions} from "../../lib/firebase";
import {functions as F} from "../../constants/functions";
import {actions as A} from "../../constants/actions";
import {cookies as K} from "../../constants/cookies";
import {deleteCookie} from "../../lib/utils/cookies";

export const createSession = ({cart, customerId = null, mode = 'payment'}) => {
    return (dispatch) => {
        dispatch({
            type: A.CREATE_SESSION
        });

        const items = cart.map(item => {
            const {quantity, id: price, name: description} = item;

            return {
                price,
                quantity,
                description
            }
        });

        const createSession = functions.httpsCallable(F.CREATE_SESSION);
        return createSession({items, customerId, mode})
            .then(response => {
                const {id} = response.data;
                dispatch({
                    type: A.CREATE_SESSION_SUCCESS,
                    payload: {sessionId: id}
                });
                dispatch({
                    type: A.CART_EMPTY
                });
                deleteCookie(K.cart);

                return {id}
            })
            .catch((err) => {
                dispatch({
                    type: A.CREATE_SESSION_FAILURE,
                    payload: {errorMessage: err.message}
                })
            });
    };
};
