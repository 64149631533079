import {actions as A} from "../constants/actions";
import {cookies as K} from "../constants/cookies";
import {setCookie} from "../lib/utils/cookies";

const defaultState = {
    stationId: null,
    hash: null,
    loading: false,
    error: false,
    errorMessage: ''
};

export default (state = defaultState, {type, payload}) => {
    switch(type) {
        case A.GET_STATION_ID_BY_HASH:
            return {...state, loading: true};

        case A.CHECK_STATION_ID:
            return {...state, loading: true};

        case A.CHECK_STATION_ID_SUCCESS:
            const {stationId, hash} = payload;
            setCookie(K.stationId, stationId);
            setCookie(K.hash, hash);
            return {...state, stationId, hash, loading: false};

        case A.CHECK_STATION_ID_FAILURE:
            const {errorMessage} = payload;
            return {...state, errorMessage, error: true, loading: false};

        case A.LOG_OUT:
            return {...defaultState};

        default:
            return state;
    }
}
