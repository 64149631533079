import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import withUser from "../../components/hoc/withUser";
import withVerifiedUser from "../../components/hoc/withVerifiedUser";
import withStripeCustomer from "../../components/hoc/withStripeCustomer";
import Account from './Account-redux';

export default compose(
    withRouter,
    withUser,
    withVerifiedUser,
    withStripeCustomer
)(Account);
