import tabletFront from "../assets/tablet-front.jpg";
import tabletBack from "../assets/tablet-back.jpg";
import tablet01 from "../assets/tablet-01.jpg";
import tablet02 from "../assets/tablet-02.jpg";
import tablet03 from "../assets/tablet-03.jpg";
import base01 from "../assets/base-01.jpg";
import base02 from "../assets/base-02.jpg";
import base03 from "../assets/base-03.jpg";
import base04 from "../assets/base-04.jpg";

export const defaultImagesAnalytics = [tabletFront, tabletBack, tablet01, tablet02, tablet03];
export const defaultImagesKiosk = [base01, base02, base03, base04];


const analytics = {
    id: "price_1HwpKjFW5w8GcFj4yO8UdvKR",
    name: "Trace Check-In Analytics",
    properties: {
    },
    prices: {
        USD: 1,
    },
    imageSrc: [tabletFront, tabletBack, tablet01, tablet02, tablet03],
    path: "",
    description: "",
    mode: 'subscription',
    enabled: true,
    requireUser: true,
};


const tablet = {
    id: "trace-check-in-tablet",
    name: "Trace Check-In tablet",
    prices: {
        USD: 100,
    },
    imageSrc: [base01, base02, base03, base04],
    path: "",
    description: `
    10.1'' RK3288 Touch Screen Android Digital Signage for room booking with Muilt-color LED Light bars and NFC/RFID
Product Product features:
OS: Android 8.1
Various input-output ports: DC in, HDMI output, mini USB OTG,USB 2.0 host, 3.5mm audio jack, Serial port, RJ45(PoE), SD card slot
Connection: supports Wi-Fi, Bluetooth 4.0, RJ45, PoE optional, NFC, RFID
Built for 24/7 use: strong durability supported to present business messaging 24/7
Full HD image: class-A LCD panel, HD image
`,
    mode: 'payment',
    enabled: false,
    requireUser: false,
};

const base = {
    id: "trace-check-in-base",
    name: "Trace Check-In metal base",
    prices: {
        USD: 100,
    },
    imageSrc: [base01, base02, base03, base04],
    path: "",
    description: `
    Specifications of touch monitor stand
    Suit for 10inch-32inch lcd/touch monitor /all in one pos
    Stand Dimension :220mm*180mm*265mm
    Net Weight:1.6 KG
    Gross Weight: 2 KG
    Support : up to 18KG
    Cables: hiding inside the stand
    Rotating Angle: 0°- 90°
    Color: Black or White
    Inner Carton Dimension : 317mm*198mm*82mm
    Master Carton Dimension : 652mm*416mm*269mm
    Meet VESA standard: 75*75mm and 100*100mm
    Material:Aluminum alloy with powder painting
    `,
    mode: 'payment',
    enabled: false,
    requireUser: false,
};

export const products = [analytics, tablet, base];
