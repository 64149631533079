import {connect} from "react-redux";
import Login from "./Login";
import {getStationIdByHash} from "../../actions/getStationIdByHash";
import {checkStationId} from "../../actions/checkStationId";
import {setStationId} from "../../actions/setStationId";
import {setAuthError} from "../../actions/auth/setAuthError";
import {setAuthSuccess} from "../../actions/auth/setAuthSuccess";
import {getStationIdByOwnerUid} from "../../actions/getStationIdByOwnerUid";

const mapStateToProps = ({
    station: {stationId, loading, error, errorMessage}
                         }) => ({
    loading,
    error,
    errorMessage,
    stationId
});

export default connect(
    mapStateToProps,
    {
        getStationIdByHash,
        checkStationId,
        setStationId,
        setAuthError,
        setAuthSuccess,
        getStationIdByOwnerUid
    }
)(Login);
