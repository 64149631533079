import {connect} from "react-redux";
import Logs from "./Logs";

const mapStateToProps = ({
    logs: {logs, loading, error, errorMessage}
                         }) => ({
    logs,
    loading,
    error,
    errorMessage
});

export default connect(
    mapStateToProps,
    null
)(Logs);
