import React from 'react';
import PropTypes from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Alert from 'react-bootstrap/Alert';
import plus from '../../assets/iconfinder_ic_control_point_48px_352288.svg'
import styles from './styles.module.scss';

const PanelSelectStationView = ({
                               stations,
                               current,
                               selectStation,
                               createNew,
                           }) => (
    <>
        <ListGroup>
            {(stations.length === 0)
                ? <Alert variant={'secondary'} className={styles.emptyList}>You don't have any stations yet.</Alert>
                : stations.map((station, index) => {
                    const {stationId, hash, isActive} = station;

                    return (
                        <ListGroup.Item
                            key={index}
                            action
                            onClick={() => selectStation(stationId)}
                            active={stationId === current}
                        >
                            <div className={styles.stationId}>
                                {stationId}
                                <span className={'ml-1 font-italic'}>{hash}</span>
                            </div>
                            {!isActive && (
                                <span className={styles.alert}>
                                    This station must be activated!
                                </span>
                            )}
                        </ListGroup.Item>
                    )
                })
            }
        </ListGroup>
        <ButtonGroup className={"p-sm-2 w-sm-25 mr-sm-auto ml-sm-auto"}>
            <Button
                variant="primary"
                onClick={createNew}
                className={styles.btnNew}
            >
                <img src={plus} alt="add" className={styles.imgPlus}/>
                <span>Add new station</span>
            </Button>
        </ButtonGroup>
    </>
);

PanelSelectStationView.propTypes = {
    stations: PropTypes.array.isRequired,
    current: PropTypes.string,
    selectStation: PropTypes.func.isRequired,
    createNew: PropTypes.func.isRequired,
};

export default PanelSelectStationView;
