import React, {Component} from "react";
import {compose} from 'redux';
import {connect} from "react-redux";
import {auth} from "../../lib/firebase";
import AlertVerifyFirst from "../AlertVerifyFirst";
import {setEmailVerified} from "../../actions/auth/setEmailVerified";

function withVerifiedUser(WrappedComponent) {
    return class extends Component {
        componentDidMount() {
            const {user, setEmailVerified} = this.props;
            if (user === null) return;
            if (!this.props.user.emailVerified) {
                setEmailVerified(auth.currentUser.emailVerified);
            }
        }

        render() {
            const {user, ...rest} = this.props;
            return (user !== null)
                ? <WrappedComponent {...rest}/>
                : <AlertVerifyFirst />;
        }
    }
}

const mapStateToProps = ({
                             user: {user}
                         }) => {
    return {
        user
    }
};

export default compose(connect(mapStateToProps, {setEmailVerified}), withVerifiedUser);
