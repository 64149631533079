import React from "react";
import PropTypes from "prop-types";
import Card from 'react-bootstrap/Card';
import styles from "./styles.module.scss";
import ChartPie from "../../ChartPie";

const PanelDetails = ({
                          macAddresses,
                          rssiValues,
                          filterValues
                      }) => {
    const macAddressesTitle = "MAC Addresses";
    const rssiValuesTitle = "RSSI values";
    const filterValuesTitle = "Filters";
    const optsLegendBottom = {
        legend: {
            position: "right",
            formatter: function (val) {
                const shortedVal = `${val.slice(0, 9)}...`;
                return (val.length > 10)
                    ? shortedVal
                    : val;
            },
        }
    };

    return (
        <Card
            border="primary"
            text="primary"
        >
            <Card.Header><h2>Details</h2></Card.Header>
            <Card.Body>
                <div className={styles.chart}>
                    <ChartPie
                        title={macAddressesTitle}
                        opts={optsLegendBottom}
                        {...macAddresses}
                    />
                </div>
                <div className={styles.chart}>
                    <ChartPie title={rssiValuesTitle} {...rssiValues}/>
                </div>
                <div className={styles.chart}>
                    <ChartPie title={filterValuesTitle} {...filterValues}/>
                </div>
            </Card.Body>
        </Card>
    )
};

PanelDetails.propTypes = {
    macAddresses: PropTypes.object.isRequired,
    rssiValues: PropTypes.object.isRequired,
    filterValues: PropTypes.object.isRequired
};

export default PanelDetails;
