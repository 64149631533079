import React, {Component} from "react";
import PropTypes from "prop-types";
import {dashboardGraphs as F} from "../../constants/dashboardFilters";
import Filter from "./Filter";
import ChartLine from "../ChartLine";
import AlertWarning from "../AlertWarning";

const defaultState = {
    scale: F.hours
};

class PanelGraphs extends Component {
    state = defaultState;

    handleScaleChange = (scale) => this.setState({scale});

    render() {
        const {scale} = this.state;
        const {
            chartsDataHourly,
            chartsDataDaily,
            chartsDataMonthly,
        } = this.props.data;
        const warning = "There not enough data to show. Please, change time scale or get more scans";
        let series = chartsDataHourly;
        let title = "Scans per hour";

        if (scale === F.months) {
            series = chartsDataMonthly;
            title = "Scans per month";
        } else if (scale === F.days) {
            series = chartsDataDaily;
            title = "Scans per day";
        } else if (scale === F.hours) {
            series = chartsDataHourly;
            title = "Scans per hour";
        }
        const {data} = series[0];
        return (
            <>
                <div
                    className="mb-3 d-flex justify-content-start align-items-center w-100"
                >
                    <h2 className="mr-2">Scale</h2>
                    <Filter filter={scale} onChange={this.handleScaleChange}/>
                </div>
                {(data.length > 1)
                    ? <ChartLine title={title} data={series}/>
                    : <AlertWarning message={warning}/>}
            </>
        )
    }
}

PanelGraphs.propTypes = {
    chartsDataHourly: PropTypes.func,
    chartsDataDaily: PropTypes.func,
    chartsDataMonthly: PropTypes.func,
};

export default PanelGraphs;
