import React from 'react';
import mammoth from 'mammoth';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const FileContent = ({filePath, className}) => {
    fetch(filePath)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
            mammoth.convertToHtml({arrayBuffer: arrayBuffer})
                .then(function(result){
                    const html = result.value; // The generated HTML
                    const container = document.getElementById("fileContentContainer");
                    if (container) container.innerHTML = html;
                })
                .done();
        });

    return (
        <div
            id="fileContentContainer"
            className={`${styles.fileContentContainer} ${className}`}
        > </div>
    )
};

FileContent.propTypes = {
    filePath: PropTypes.string.isRequired,
};

export default FileContent;
