import {auth} from "../lib/firebase";
import {actions as A} from "../constants/actions";
import {clearAllCookies} from "../lib/utils/cookies";
import {shutdown} from "../lib/intercom";

export const logOut = () => {
    return (dispatch) => {
        clearAllCookies();
        shutdown();
        auth.signOut();
        dispatch({
            type: A.CART_EMPTY
        });

        return dispatch({
            type: A.LOG_OUT
        })
    };
};
