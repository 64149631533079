import {actions as A} from "../../constants/actions";

export const setEmailVerified = emailVerified => {
    return (dispatch) => {
        return dispatch({
            type: A.SET_EMAIL_VERIFIED,
            payload: {emailVerified}
        })
    };
};
