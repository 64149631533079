import React from 'react';
import styles from './styles.module.scss';

const header = 'What’s the process?';
const description = 'Getting started with Trace Check-In for your workplace is simple and can be coordinated within 1-3 weeks. As your team prepares to engage with the Trace Check-In kiosk, we will collaborate with you to ensure the right communications are in place to set your team up for successful integration into your existing workplace protocols.';
const list = [
    {
        id: 1,
        title: 'Plan',
        text: 'Your team is planning their safe return to work strategy or seeking additional ways to ensure the safety and wellbeing of your workplace.'
    },
    {
        id: 2,
        title: 'Consult',
        text: 'Your Steelcase contact will consult with you and provide additional information on how Trace Check-In would work for your unique needs.'
    },
    {
        id: 3,
        title: 'Order',
        text: 'Once the order is placed for your Trace Check-In tablet, you will receive your device within 1-3 weeks. You can also choose to procure a device on your own and install the Trace Check-in app.'
    },
    {
        id: 4,
        title: 'Install',
        text: 'Installation of the tablet depends on the current set-up of your space and can range from simply being placed on a desk, to coordinating wall mounting. Installation support can be provided.'
    },
    {
        id: 5,
        title: 'Communicate',
        text: 'Suggested communications are sent to your team to bring awareness to the Trace Check-In process. We recommend to include this as part of your overall safety and protocol messaging.'
    },
    {
        id: 6,
        title: 'Day One',
        text: 'Before employees enter their workplace, they will need to install the local health app containing exposure notification technology. Then, as employees enter their workplace they will use the Trace Check-In kiosk as part of their daily routine and will only require a few seconds as they pass by.'
    },
    {
        id: 7,
        title: 'Update',
        text: 'Automatic software updates are sent to your device to maintain optimal functioning without disrupting your process.'
    },
    {
        id: 8,
        title: 'Enhance',
        text: 'Additional analytics can be provided to give higher awareness around adoption and participation rates of the exposure notification technology'
    },
];

const Process = () => (
    <section className={styles.processContainer}>
        <h2>{header}</h2>
        <p className={styles.description}>{description}</p>
        <div className={styles.list}>
            {list.map(item => {
                const {id, title, text} = item;
                return (
                    <div key={id} className={styles.listItem}>
                        <div className={styles.number}>{id}</div>
                        <h3 className={styles.title}>{title}</h3>
                        <div className={styles.text}>{text}</div>
                    </div>
                )
            })}
        </div>
    </section>
);

export default Process;
