import {functions} from "../lib/firebase";
import {functions as F} from "../constants/functions";
import {actions as A} from "../constants/actions";

export const getHmacByEmail = email => {
    return (dispatch) => {
        dispatch({
            type: A.GET_HMAC_BY_EMAIL
        });
        const getHmacByEmail = functions.httpsCallable(F.GET_HMAC_BY_EMAIL);

        return getHmacByEmail({email})
            .then(response => {
                const hmac = response.data;
                return dispatch({
                    type: A.GET_HMAC_BY_EMAIL_SUCCESS,
                    payload: {hmac}
                })
            })
            .catch((err) => {
                return dispatch({
                    type: A.GET_HMAC_BY_EMAIL_FAILURE,
                    payload: {errorMessage: err.message}
                })
            });
    };
};
