import React from 'react';
import PropTypes from 'prop-types'
import Router from '../../router';
import config from '../../config/config';
import {auth} from "../../lib/firebase";
import {boot} from "../../lib/intercom";

function App({user, setUser, checkAndCreateCustomer, hmac, getHmacByEmail}) {
    auth.onAuthStateChanged(function(user) {
        setUser(user);
        if (user) checkAndCreateCustomer();
    });

    if (user) {
        const {displayName, email} = user;
        getHmacByEmail(email).then(() => {
            boot({
                name: displayName,
                email,
                hmac
            })
            /*if (hmac !== null) {
                window.Intercom('boot', {
                    app_id: config.intercom_app_id,
                    name: `${displayName}`, // Full name
                    email: `${email}`, // Email address
                    created_at: new Date().getTime(), // Signup date as a Unix timestamp
                    user_hash: hmac
                });
            }*/
        })
    } else {
        boot({});
    }

    return (
        <Router/>
    );
}

App.propTypes = {
    user: PropTypes.object,
    hmac: PropTypes.string,
    getHmacByEmail: PropTypes.func
};

export default App;
