import {actions as A} from "../constants/actions";

const defaultState = {
    ok: false,
    loading: true,
    error: false,
    errorMessage: ''
};

export default (state = defaultState, {type, payload}) => {
    switch(type) {
        case A.REGISTER_STATION_BY_CODE:
            return {...state};

        case A.REGISTER_STATION_BY_CODE_SUCCESS:
            const {ok} = payload;
            return {...state, ok, loading: false};

        case A.REGISTER_STATION_BY_CODE_FAILURE:
            const {errorMessage} = payload;
            return {...state, errorMessage, error: true, loading: false};

        default:
            return state;
    }
}
