import React from "react";
import PropTypes from "prop-types";
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./styles.module.scss";

const PanelAvgValues = ({
                            uniqueMacAddressesPerHour,
                            avgRssi,
                            numberOfFilters,
                            totalScans
                        }) => (
    <Card
        className={styles.cardBg}
        border="primary"
        text="primary"
    >
        <Card.Header><h2>Summary</h2></Card.Header>
        <Card.Body>
            <Container>
                <Row className={styles.line}>
                    <Col>
                        Unique MAC addresses per hour:
                    </Col>
                    <Col md="auto">
                        <strong>{
                            (Number.isInteger(uniqueMacAddressesPerHour))
                                ? uniqueMacAddressesPerHour
                                : uniqueMacAddressesPerHour.toFixed(2)
                        }</strong>
                    </Col>
                </Row>
                <Row className={styles.line}>
                    <Col>
                        Average RSSI:
                    </Col>
                    <Col md="auto">
                        <strong>{avgRssi}</strong>
                    </Col>
                </Row>
                <Row className={styles.line}>
                    <Col>
                        Number of filters:
                    </Col>
                    <Col md="auto">
                        <strong>{numberOfFilters}</strong>
                    </Col>
                </Row>
                <Row className={styles.line}>
                    <Col>
                        Total scans:
                    </Col>
                    <Col md="auto">
                        <strong>{totalScans}</strong>
                    </Col>
                </Row>
            </Container>
        </Card.Body>
    </Card>
);

PanelAvgValues.propTypes = {
    uniqueMacAddressesPerHour: PropTypes.number.isRequired,
    avgRssi: PropTypes.number.isRequired,
    numberOfFilters: PropTypes.number.isRequired,
    totalScans: PropTypes.number.isRequired
};

export default PanelAvgValues;
