import {functions} from "../../lib/firebase";
import {functions as F} from "../../constants/functions";
import {actions as A} from "../../constants/actions";

export const getStationsByOwner = (uid) => {
    return (dispatch) => {
        dispatch({
            type: A.GET_STATIONS_BY_OWNER
        });
        const getStationsByOwner = functions.httpsCallable(F.GET_STATIONS_BY_OWNER);

        return getStationsByOwner({uid})
            .then(response => {
                dispatch({
                    type: A.GET_STATIONS_BY_OWNER_SUCCESS,
                    payload: {stations: response.data}
                })
            })
            .catch((err) => {
                dispatch({
                    type: A.GET_STATIONS_BY_OWNER_FAILURE,
                    payload: {errorMessage: err.message}
                })
            });
    };
};
