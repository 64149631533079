import {functions} from "../lib/firebase";
import {functions as F} from "../constants/functions";
import {actions as A} from "../constants/actions";

export const getLogs = stationId => {
    return (dispatch) => {
        dispatch({
            type: A.GET_LOGS
        });
        const getLogs = functions.httpsCallable(F.GET_LOGS);

        return getLogs({stationId})
            .then(response => {
                dispatch({
                    type: A.GET_LOGS_SUCCESS,
                    payload: {logs: response.data.logs}
                })
            })
            .catch((err) => {
                dispatch({
                    type: A.GET_LOGS_SUCCESS,
                    payload: {errorMessage: err.message}
                })
            });
    };
};
