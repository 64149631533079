import {connect} from 'react-redux';
import CartIcon from './CartIcon';

const mapStateToProps = ({
    cart: {products}
                         }) => ({
    products
});

export default connect(
    mapStateToProps,
    null
)(CartIcon);
