import React, {Component} from "react";
import {compose} from 'redux';
import {connect} from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {getStationsByOwner} from "../../actions/stations/getStationsByOwner";

function withStation(WrappedComponent) {
    return class extends Component {
        componentDidMount() {
            const {getStationsByOwner, uid} = this.props;
            getStationsByOwner(uid)
        }

        render() {
            const {stations, getStationsByOwner, uid, ...rest} = this.props;

            return (stations !== null)
                ? <WrappedComponent {...rest}/>
                : <Spinner animation="border" />;
        }
    }
}

const mapStateToProps = ({
                             stations: {stations},
                             user: {user: {uid}}
                         }) => {
    return {
        stations,
        uid
    }
};

export default compose(connect(mapStateToProps, {getStationsByOwner}), withStation);
