import {cookies as K} from "../../constants/cookies";
import {setCookie, deleteCookie} from "../../lib/utils/cookies";

export const cartMW = store => next => action => {
    const result = next(action);
    const {type} = action;
    if (type === "cart/ADD" || type === "cart/REMOVE") {
        const {cart} = store.getState();
        const cartWithoutImages = removeImages(cart);
        deleteCookie(K.cart);
        setCookie(K.cart, JSON.stringify(cartWithoutImages));
    }

    return result;
};

const removeImages = cart => {
    const {currency, products} = cart;
    const cartWithoutImages = {currency, products: {}};
    const keys = Object.keys(products);
    keys.forEach(key => {
        const {imageSrc, ...rest} = products[key];
        cartWithoutImages.products[key] = rest;
    });

    return cartWithoutImages;
};
