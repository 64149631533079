import React from "react";
import PropTypes from "prop-types";
import TabControlLogs from "../../components/TabContolLogs/";
import AlertLoading from "../../components/AlertLoading/AlertLoading";
import AlertError from "../../components/AlertError";
import styles from "./styles.module.scss";

const LogsView = ({
                      logs,
                      chartsDataHourly,
                      chartsDataDaily,
                      chartsDataMonthly,
                      isLoading,
                      isError,
                      errorMessage
}) => {
    const data = {
        chartsDataHourly,
        chartsDataDaily,
        chartsDataMonthly,
    };

    return (
    <div className={styles.container}>
        {(logs.length > 0) && <TabControlLogs data={data} logs={logs} />}
        {isLoading && <AlertLoading />}
        {isError && <AlertError message={errorMessage} />}
    </div>
)
};

LogsView.propTypes = {
    logs: PropTypes.array.isRequired,
    chartsDataHourly: PropTypes.array.isRequired,
    chartsDataDaily: PropTypes.array.isRequired,
    chartsDataMonthly: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired
};

export default LogsView;
