import {connect} from 'react-redux';
import Store from './Store';

const mapStateToProps = ({
    user: {user},
    products: {products}
                         }) => ({
    user,
    products
});

export default connect(
    mapStateToProps,
    null
)(Store);
