import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import social from '../../assets/img-social.png';
import {routes as R} from "../../constants/routes";
import styles from "./styles.module.scss";

const SupportLink = () => (
    <Link to={R.HELP_CENTER}>
        visit our help center
    </Link>
);

const LoginView = ({
                       uiConfig,
                       auth
                   }) => {
        return (
            <div className={`mb-3 ${styles.container}`}>
                <Container>
                    <Row>
                        <Col lg className={styles.col}>
                            <div className={styles.about}>
                                <h1 className={styles.title}>Welcome to Trace
                                    Check-In</h1>
                                <p className={styles.subTitle}>Our team is delighted
                                    to support your safe return to work. Let’s get
                                    started.</p>
                                <p className={styles.text}>Sign in with your email
                                    below, or <SupportLink/> for support. You
                                    can also jump straight to our live chat feature
                                    in the bottom right with any questions.</p>
                                <img src={social} alt="social"
                                     className={styles.image}/>
                            </div>
                        </Col>
                        <Col lg className={styles.col}>
                            <StyledFirebaseAuth
                                uiConfig={uiConfig}
                                firebaseAuth={auth}
                            />

                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
;

LoginView.propTypes = {
    state: PropTypes.string,
    errorMessage: PropTypes.string,
    uiConfig: PropTypes.object,
    auth: PropTypes.object,

};

export default LoginView;
