import React, {Component} from "react";
import {compose} from 'redux';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Spinner from 'react-bootstrap/Spinner';
import {getLogs} from "../../actions/logs";

function withScanResults(WrappedComponent) {
    return class extends Component {
        static propTypes = {
            logs: PropTypes.array
        };

        render() {
            const {logs, stationId, getLogs, ...rest} = this.props;
            if (logs === null) {
                getLogs('18287482-95c4-42e7-8712-29970c1dc3d7');
            }
            return (logs === null)
                ? <Spinner animation="border" variant="primary"/>
                : <WrappedComponent {...rest}/>;
        }
    }
}

const mapStateToProps = ({
                             logs: {logs},
                             station: {stationId}
                         }) => {
    return {
        logs,
        stationId
    }
};

export default compose(connect(mapStateToProps, {
    getLogs
}), withScanResults);

