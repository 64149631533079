import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {routes as R} from "../../../constants/routes";
import accountIcon from '../../../assets/iconfinder_ic_account_circle_48px_352002.svg';
import styles from "../styles.module.scss";

const MenuDefault = ({isLogged}) => (
    <div className={`${styles.linksOnRight} d-flex`}>
        {
            (isLogged)
                ? (
                    <NavLink
                        to={R.ACCOUNT}
                        className="nav-link"
                    >
                        <img src={accountIcon} alt="acc"/>
                        <span>Account</span>
                    </NavLink>
                )
                : (
                    <NavLink
                        to={R.LOGIN}
                        className="nav-link"
                    >
                        Log in
                    </NavLink>
                )
        }
    </div>
);

MenuDefault.propTypes = {
    isLogged: PropTypes.bool,
};

export default MenuDefault;
