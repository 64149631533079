import React, {useState, Children, isValidElement, cloneElement} from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "./styles.module.scss";

const ButtonWithModal = ({
                             variant,
                             label,
                             labelClassName,
                             children
                         }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const modalWithProps = Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, {close: handleClose})
        }

        return child;
    });
    return (
        <div>
            <Button variant={variant} onClick={handleShow} className={labelClassName}>{label}</Button>
            <Modal show={show} onHide={handleClose}>
                {modalWithProps}
            </Modal>
        </div>
    )
};

ButtonWithModal.propTypes = {};

export default ButtonWithModal;
