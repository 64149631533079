import React from "react";
import {NavLink} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import {routes as R} from "../../constants/routes";
import styles from "./styles.module.scss";

const AlertRegisterFirst = () => (
    <Alert variant="warning">
        <Alert.Heading>You should register your station first!</Alert.Heading>
        <p>You will not see any station information if you didn't register it</p>
        <p>Please register with your Station ID</p>
        <Button variant="primary" className={"ml-auto mr-auto d-block"}>
            <NavLink
                to={R.REGISTRATION}
                className={`nav-link ${styles.btn}`}
            >
                Register
            </NavLink>
        </Button>
    </Alert>
);
export default AlertRegisterFirst;
