import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import {NavLink} from "react-router-dom";
import {routes as R} from '../../constants/routes';

const Checkout = ({
                      products,
                      productList,
                      customerId,
                      createSession,
                      handleCheckout
                  }) => {
    const query = new URLSearchParams(document.location.search);
    const success = query.get('success');
    const canceled = query.get('canceled');
    const cart = Object.values(products);
    const namesInCart = cart.map(item => item.name);
    const modesInCart = productList
        .map(item => {
            return (namesInCart.indexOf(item.name) !== -1) ? item.mode : null
        })
        .filter(Boolean);
    const mode = (modesInCart.indexOf('subscription') !== -1)? 'subscription': 'payment';

    if (success) {
        return (
            <>
                <Alert variant="primary">
                    Operation was successful
                </Alert>
                <Button variant="primary" className={"ml-auto mr-auto d-block"}>
                    <NavLink
                        to={R.STORE}
                        className={`nav-link`}
                    >
                        Back to store
                    </NavLink>
                </Button>
            </>
        )
    } else if (canceled) {
        return (
            <>
                <Alert variant="danger">
                    Operation was canceled
                </Alert>
                <Button variant="primary" className={"ml-auto mr-auto d-block"}>
                    <NavLink
                        to={R.STORE}
                        className={`nav-link`}
                    >
                        Back to store
                    </NavLink>
                </Button>
            </>
        )
    } else if (cart.length > 0) {
        handleCheckout({cart, createSession, customerId, mode});

        return (
            <Spinner animation="border" variant="primary"/>
        )
    } else {
        return <Redirect to={R.STORE}/>
    }
};

Checkout.propTypes = {
    products: PropTypes.object,
    customerId: PropTypes.string,
    createSession: PropTypes.func,
    handleCheckout: PropTypes.func
};

export default Checkout;
