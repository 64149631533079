import moment from "moment";
import {getTotalHoursBetweenDates} from "./utils/timestamp";

export const getLabelsAndSeriesByField = field => logs => {
    const uniqueItems = getUniqueItems(field)(logs);
    const uniqueItemsCount = getItemsCount(field)(logs, uniqueItems);

    return {
        series: uniqueItemsCount,
        labels: uniqueItems
    }
};

const getUniqueItems = field => logs => {
    const uniqueItems = logs.reduce((acc, log) => {
        acc.add(log[field]);
        return acc;
    }, new Set());
    return Array.from(uniqueItems);
};

const getItemsCount = field => (logs, items) => {
    return items.map(item => {
        return getLogsByField(field)(logs, item).length
    })
};

const getLogsByField = field => (logs, item) => {
    return logs.filter(log => {
        return log[field] === item;
    })
};

export const getRssiValuesFromLogs = getLabelsAndSeriesByField('rssi');
export const getMacAddressesFromLogs = getLabelsAndSeriesByField('address');
export const getFiltersFromLogs = getLabelsAndSeriesByField('filter');

export const getUniqueMacAddressesPerHour = logs => {
    const uniqueAddresses = getUniqueItems('address')(logs);
    const minDateTime = getMinimalTime(logs);
    const maxDateTime = getMaximalTime(logs);
    const totalHours = getTotalHoursBetweenDates(maxDateTime, minDateTime);
    return (totalHours > 0)
        ? uniqueAddresses.length / totalHours
        : uniqueAddresses.length;
};

export const getMinimalTime = logs => {
    let minimalTime = moment(logs[0].timestamp);

    logs.forEach(log => {
        const date = moment(log.timestamp);
        if (date.valueOf() < minimalTime.valueOf()) minimalTime = date;
    });

    return minimalTime;
};

export const getMaximalTime = logs => {
    let maximalTime = moment(logs[0].timestamp);

    logs.forEach(log => {
        const date = moment(log.timestamp);
        if (date.valueOf() > maximalTime.valueOf()) maximalTime = date;
    });

    return maximalTime;
};
