import React from 'react';
import {NavLink} from 'react-router-dom';
import {routes as R} from '../../../constants/routes';
import arrow from '../../../assets/landing/arrow-small-1.svg';
import styles from './styles.module.scss';

const header1 = 'What’s';
const header2 = 'the process?';
const description = 'Getting started with Trace Check-In for your workplace is simple and can be coordinated within 1-3 weeks.';
const learn = 'Learn about our customer process ';

const ProcessMobile = () => (
    <section className={styles.processContainer}>
        <div className={styles.greenContainer}>
            <h2>{header1}</h2>
            <h2>{header2}</h2>
            <p>{description}</p>
            <NavLink
                to={R.PROCESS}
                className={styles.link}
            >
                <span>{learn}</span>
                <img src={arrow} alt="arrow"/>
            </NavLink>
        </div>
    </section>
);

export default ProcessMobile;
