import React, {Component} from "react";
import {compose} from 'redux';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {routes as R} from "../../constants/routes";
import {auth} from "../../lib/firebase";

function withLogin(WrappedComponent) {
    return class extends Component {
        render() {
            const {user, pathname, ...rest} = this.props;
            const page = (!Boolean(pathname) || pathname === R.HOME)
                ? R.ACCOUNT
                : pathname;
            return (user === null || auth.currentUser === null)
                ? <WrappedComponent {...rest}/>
                : <Redirect to={page}/>;
        }
    }
}

const mapStateToProps = ({
                             user: {user},
                             router: {location: {pathname}}
                         }) => {
    return {
        user,
        pathname
    }
};

export default compose(connect(mapStateToProps, null), withLogin);
