import {connect} from 'react-redux';
import PanelSelectStation from './PanelSelectStation';
import {setStationId} from "../../actions/setStationId";
import {getSettingsByStationId} from "../../actions/getSettingsByStationId";

const mapStateToProps = ({
    station: {stationId},
    stations: {stations}
                         }) => ({
    stationId,
    stations
});

export default connect(
    mapStateToProps,
    {setStationId, getSettingsByStationId}
)(PanelSelectStation);
