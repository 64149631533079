import {actions as A} from "../constants/actions";
import {getStationIdShortHash} from "../lib/hash";

export const setStationId = stationId => {
    return (dispatch) => {
        const hash = getStationIdShortHash(stationId);

        return dispatch({
            type: A.CHECK_STATION_ID_SUCCESS,
            payload: {stationId, hash}
        })
    };
};
