import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {dashboardGraphs as F} from "../../../constants/dashboardFilters";
import styles from "./styles.module.scss";

const Filter = ({
                        filter,
                        onChange
                    }) => (
    <div className={styles.filter}>
        <ButtonGroup
            size="lg"
            toggle={true}
        >
            <Button
                variant="secondary"
                className={(filter === F.hours)? "active" : ""}
                onClick={() => onChange(F.hours)}
            >
                Hours
            </Button>
            <Button
                variant="secondary"
                className={(filter === F.days)? "active" : ""}
                onClick={() => onChange(F.days)}
            >
                Days
            </Button>
            <Button
                variant="secondary"
                className={(filter === F.months)? "active" : ""}
                onClick={() => onChange(F.months)}
            >
                Months
            </Button>
        </ButtonGroup>
    </div>
);

Filter.propTypes = {
    filter: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default Filter;
