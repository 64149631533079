export const routes = {
    HOME: "/",
    PROCESS: "/process",
    REGISTRATION: "/register",
    LOGS: '/analytics',
    LOGIN: '/login',
    LOGOUT: '/logout',
    STATION: '/station',
    ACCOUNT: '/account',
    HELP_CENTER: '/support',
    SELECT_STATION: '/selectStation',
    STORE: '/store',
    STORE_CART: '/store/cart',
    STORE_CHECKOUT: '/store/checkout',
    STORE_SUCCESS: '/store/success',
    STORE_CANCEL: '/store/cancel',
    TERMS_OF_SERVICE: '/terms',
    PRIVACY_POLICY: '/privacy',
};
