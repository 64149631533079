export const defaultSettings = {
    activeRssi: -80,
    highRssi: -60,
    highRssiOffset: 33,
    isActive: false,
    isFaceDetectionEnabled: true,
    loggingPeriod: 10000,
    scanFilters: [
        "0000fd6f-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
    ]
};
