import React from 'react';
import PropTypes from 'prop-types';
import {
    Cart as RSCart,
    CheckoutButton,
} from "react-shopping-cart";
import {NavLink} from "react-router-dom";
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import {routes as R} from '../../constants/routes';

import styles from './styles.module.scss';

const Cart = ({products}) => {
    const cartIsEmpty = Object.keys(products).length === 0;

    return (
        <div className={styles.cartContainer}>
            {
                cartIsEmpty
                    ? <Alert variant="primary" className={'text-center'}>Empty</Alert>
                    : (
                        <RSCart
                            checkoutButton={(
                                <CheckoutButton
                                    checkoutURL={R.STORE_CHECKOUT}
                                    linkComponent={NavLink}
                                />
                            )}
                        />
                    )
            }
            <Button type="button" variant="primary">
                <NavLink to={R.STORE} className={'nav-link'}>Back to Store</NavLink>
            </Button>
        </div>
    )
};

Cart.propTypes = {
    products: PropTypes.object
};

export default Cart;
