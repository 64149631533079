import React from "react";
import Alert from "react-bootstrap/Alert";
import styles from "./styles.module.scss";

const AlertLoading = () => (
    <Alert variant="info">
        Loading...
    </Alert>
);

export default AlertLoading;
