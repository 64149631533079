import {functions} from "../lib/firebase";
import {functions as F} from "../constants/functions";
import {actions as A} from "../constants/actions";

export const setSettingsForStationId = (stationId, settings) => {
    return (dispatch) => {
        dispatch({
            type: A.SET_SETTINGS_FOR_STATION_ID
        });
        const setSettingsForStationId = functions.httpsCallable(F.SET_SETTINGS_FOR_STATION_ID);

        return setSettingsForStationId({stationId, settings})
            .then(() => {
                dispatch({
                    type: A.SET_SETTINGS_FOR_STATION_ID_SUCCESS
                })
            })
            .catch((err) => {
                dispatch({
                    type: A.SET_SETTINGS_FOR_STATION_ID_FAILURE,
                    payload: {errorMessage: err.message}
                })
            });
    };
};
