import {connect} from "react-redux";
import PanelStationInfo from "./PanelStationInfo";
import {logOut} from "../../actions/logOut";

const mapStateToProps = ({
                             station: {stationId, hash},
                             settings: {settings: {isActive}}
                         }) => ({
    stationId,
    hash,
    isActive
});

export default connect(
    mapStateToProps,
    {logOut}
)(PanelStationInfo);
