import {actions as A} from "../../constants/actions";

export const setUser = user => {
    return (dispatch) => {
        return dispatch({
            type: A.SET_USER,
            payload: {user}
        })
    };
};
