import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import logs from "./logs";
import registerCode from "./registerCode";
import station from "./station";
import settings from "./settings";
import user from "./user";
import stations from "./stations";
import hmac from "./hmac";
import stripe from "./stripe";
import {
    cartReducer,
} from "react-shopping-cart";
import stripeCustomer from "./stripeCustomer";
import stripePortalSession from "./stripePortalSession";
import products from "./products";

const createRootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        logs,
        registerCode,
        station,
        settings,
        user,
        stations,
        hmac,
        stripe,
        stripeCustomer,
        stripePortalSession,
        cart: cartReducer,
        products
    });

export default createRootReducer;

