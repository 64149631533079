import React, {Component} from "react";
import {compose} from 'redux';
import {connect} from "react-redux";
import firebase from "firebase";
import {auth} from "../../lib/firebase";
import AlertLoginFirst from "../AlertLoginFirst";
import {setUser} from "../../actions/auth/setUser";

function withUser(WrappedComponent) {
    return class extends Component {
        render() {
            const {user, setUser, ...rest} = this.props;
            /*firebase.auth().onAuthStateChanged(function(user) {
                setUser(user);
            });*/

            return (user !== null && auth.currentUser !== null)
                ? <WrappedComponent {...rest}/>
                : <AlertLoginFirst />;
        }
    }
}

const mapStateToProps = ({
                             user: {user}
                         }) => {
    return {
        user
    }
};

export default compose(connect(mapStateToProps, {setUser}), withUser);
