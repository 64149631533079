import React from 'react';
import icon1 from '../../../assets/landing/icon-why-1.svg';
import icon2 from '../../../assets/landing/icon-why-2.svg';
import icon3 from '../../../assets/landing/icon-why-3.svg';
import icon4 from '../../../assets/landing/icon-why-4.svg';
import styles from './styles.module.scss';

const Header = () => (
    <h2>
        <span className={styles.bold}>Why</span> Trace Check-In
    </h2>
);
const list = [
    {
        id: 1,
        icon: icon1,
        title: 'Respond Strategically',
        renderText: () => (
            <>
                To help organizations instill confidence in safely managing a
                <span className={styles.bold}>strategic response</span> to
                COVID-19 and get their employees back to
                the workplace.'
            </>
        )
    },
    {
        id: 2,
        icon: icon2,
        title: 'Preserve Privacy',
        renderText: () => (
            <>
                Through leveraging the technology from Apple and Google, Trace
                Check-in provides a tool that is secure and collects
                <span className={styles.bold}>no personal data.</span>
            </>
        )
    },
    {
        id: 3,
        icon: icon3,
        title: 'Create Safer Spaces',
        renderText: () => (
            <>
                Encourages employees to participate in
                <span className={styles.bold}>creating a safe space</span>
                for everyone to do their best work.
            </>
        )
    },
    {
        id: 4,
        icon: icon4,
        title: 'Enhance Return Protocols',
        renderText: () => (
            <>
                Give employers
                <span className={styles.bold}>
                    visibility to the effectiveness of digital contact tracing
                    in the workplace,
                </span>
                and enhances return to work protocols.
            </>
        )
    },
];

const Why = () => (
    <section className={styles.whyContainer}>
        <div className={styles.inner}>
            <Header/>
            <div className={styles.reasonList}>
                {
                    list.map(item => {
                        const {id, icon, title, renderText} = item;
                        return (
                            <div key={id} className={styles.listItem}>
                                <div className={styles.circleBg}>
                                    <img src={icon} alt="icon"/>
                                </div>
                                <div>
                                    <h3 className={styles.title}>{title}</h3>
                                    <div
                                        className={styles.text}>{renderText()}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </section>
);

export default Why;
