import React from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import styles from "./styles.module.scss";

const AlertWarning = ({message}) => (
    <Alert variant="warning">
        {message}
    </Alert>
);

AlertWarning.propTypes = {
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired
};

export default AlertWarning;
