import React from 'react';
import PropTypes from 'prop-types';
import AccountView from "./AccountView";
import {routerTypes} from "../../types/routerTypes";
import {routes as R} from "../../constants/routes";

const Account = ({
                     logOut,
                     user,
                     customerId,
                     url,
                     createPortalSession,
                     history
}) => {
    const {email, displayName} = user;

    const handleLogOut = () => {
        logOut();
        history.push(R.LOGIN);
    };

    const goToCustomerPortal = () => {
        createPortalSession({customerId}).then(url => {
            window.location.href = url;
        })
    };

    return (
        <AccountView
            name={displayName}
            email={email}
            onLogOut={handleLogOut}
            onCustomerPortal={goToCustomerPortal}
        />
    )
};

Account.propTypes = {
    ...routerTypes,
    user: PropTypes.object,
    customerId: PropTypes.string,
    url: PropTypes.string,
    logOut: PropTypes.func,
    createPortalSession: PropTypes.func,
};

export default Account;
