import {
    stationIdRegExp,
    stationHashRegExp,
    stationFilterBase
} from "../constants/regexp";

export const isStationId = str => stationIdRegExp.test(str);

export const isStationHash = str => stationHashRegExp.test(str);

export const isValidStationFilter = str => stationFilterBase.test(str);
