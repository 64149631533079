import React from "react";
import PropTypes from "prop-types";
import PanelAvgValues from "./PanelAvgValues";
import PanelDetails from "./PanelDetails";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {sortTransformedLogsByField} from "../../lib/logs";
import {getUniqueMacAddressesPerHour} from "../../lib/summary";
import {getAvgFromNumbersArray} from "../../lib/utils/math";
import {
    getMacAddressesFromLogs,
    getRssiValuesFromLogs,
    getFiltersFromLogs
} from "../../lib/summary";
import styles from "./styles.module.scss";

const PanelSummary = ({logs}) => {
    const logsSortedByAddress = sortTransformedLogsByField(logs, 'address');
    const macAddresses = getMacAddressesFromLogs(logsSortedByAddress);
    const logsSortedByRssi = sortTransformedLogsByField(logs, 'rssi');
    const rssiValues = getRssiValuesFromLogs(logsSortedByRssi);
    const logsSortedByFilter = sortTransformedLogsByField(logs, 'filter');
    const filterValues = getFiltersFromLogs(logsSortedByFilter);
    const uniqueMacAddressesPerHour = getUniqueMacAddressesPerHour(logs);
    const avgRssi = getAvgFromNumbersArray(rssiValues.labels);
    const numberOfFilters = filterValues.series.length;
    const totalScans = logs.length;

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <PanelDetails
                            macAddresses={macAddresses}
                            filterValues={filterValues}
                            rssiValues={rssiValues}
                        />
                    </Col>
                    <Col md="5">
                        <PanelAvgValues
                            uniqueMacAddressesPerHour={uniqueMacAddressesPerHour}
                            avgRssi={avgRssi}
                            numberOfFilters={numberOfFilters}
                            totalScans={totalScans}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

PanelSummary.propTypes = {
    logs: PropTypes.array.isRequired
};

export default PanelSummary;
