import React from 'react';
import {NavLink} from "react-router-dom";
import {routes as R} from "../../../constants/routes";
import img from '../../../assets/landing/img-section-1.jpg'
import styles from './styles.module.scss';

const labelGetStarted = 'Get Started';
const headerJumbo = 'Supporting contract tracing in the workplace';

const Jumbotron = () => (
    <section className={styles.jumboContainer}>
        <div className={styles.col}>
            <h1>{headerJumbo}</h1>
            <NavLink
                to={R.STORE}
                className={styles.button}
            >
                {labelGetStarted}
            </NavLink>
        </div>
        <div className={styles.col}>
            <img src={img} alt="img"/>
        </div>
    </section>
);

export default Jumbotron;
