import {
    getFormattedToLocaleDateFromTimestamp,
    getDateStringFromTimestamp,
    roundHours,
    roundMinutes,
    roundDates
} from "./utils/timestamp";

export const transformLogs = (logs, timeFormat = 'locale') => {
    return logs.map(log => {
        const transformedLog = {...log.data};
        transformedLog.active = log.data.active ? "+" : "-";
        transformedLog.timestamp = (timeFormat === 'locale')
            ? getDateStringFromTimestamp(log.data.timestamp)
            : getDateStringFromTimestamp(log.data.timestamp);
        transformedLog.filter = `${log.data.uuid.slice(0, 8)}-xxxx-xxxx-xxxx-xxxxxxxxxxxx`;
        return transformedLog;
    });
};

export const buildChartData = period => logs => {
    /**
     * period can be: 'hour', 'day', 'month'
     */
    const transformedLogs = transformLogs(logs, 'string')
        .map(log => {
            return {
                ...log,
                timestamp: roundTimeToPeriod(period, log)
            }
        });
    const mappedTimes = transformedLogs.map((log, ind, logs) => {
        return roundTimeToPeriod(period, log).toString();
    });
    const uniqueTimes = new Set(mappedTimes);
    const mappedLogs = [];
    uniqueTimes.forEach(uTime => {
        mappedLogs.push({
            x: new Date(uTime),
            y: countLogsAtTime(uTime, transformedLogs)
        })
    });
    const sortedLogs = mappedLogs.sort((prev, current) => {
        if (prev.x > current.x) {
            return 1;
        }
        if (prev.x < current.x) {
            return -1;
        }
        return 0;
    });
    return [{
        name: "scans",
        data: sortedLogs
    }];
};

const roundTimeToPeriod = (period, log) => {
    const timestamp = new Date(log.timestamp);
    if (period === 'hour') {
        return roundMinutes(timestamp);
    } else if (period === 'day') {
        return roundHours(timestamp);
    } else if (period === 'month') {
        return roundDates(timestamp);
    }
};

const countLogsAtTime = (time, logs) => {
    return logs.filter(log => log.timestamp.toString() === time).length;
};

export const sortTransformedLogsByField = (logs, field) => {
    return logs.sort((prev, current) => {
        if (prev[field] < current[field]) {
            return -1;
        }
        if (prev[field] > current[field]) {
            return 1;
        }
        return 0;
    })
};

