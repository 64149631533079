import {cookies as K} from "../constants/cookies";
import {getCookie} from "./utils/cookies";

export const loadStoreFromCookies = () => {
    const stationId = getCookie(K.stationId);
    const hash = getCookie(K.hash);
    const cart = getCart(getCookie(K.cart));

    return {
        station: {stationId, hash},
        cart: {...cart}
    };
};

const getCart = rawCart => rawCart && JSON.parse(rawCart);
