import React from "react";
import {NavLink} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import {routes as R} from "../../constants/routes";
import styles from "./styles.module.scss";

const AlertVerifyFirst = () => (
    <Alert variant="warning">
        <Alert.Heading>You should verify your email first!</Alert.Heading>
        <p>Please, check your email and verify your account by link.</p>
    </Alert>
);
export default AlertVerifyFirst;
