export const functions = {
    REGISTER_STATION: 'activateStation',
    GET_LOGS: 'getLogsByStationId',
    AUTH_BY_STATION_ID: 'authByStationId',
    GET_STATION_ID_BY_HASH: 'getStationIdByHash',
    GET_STATION_ID_BY_OWNER_UID: 'getStationIdByOwnerUid',
    CHECK_STATION_ID: 'checkStationId',
    GET_SETTINGS_BY_STATION_ID: 'getSettingsByStationId',
    SET_SETTINGS_FOR_STATION_ID: 'setSettingsForStationId',
    GET_STATIONS_BY_OWNER: 'getStationsByOwner',
    GET_HMAC_BY_EMAIL: 'getHmacByEmail',
    CREATE_SESSION: 'createSession',
    GET_CUSTOMER_ID: 'getCustomerId',
    CREATE_PORTAL_SESSION: 'createPortalSession',
    GET_PRODUCTS: 'getProducts',
    CHECK_AND_CREATE_CUSTOMER: 'checkAndCreateCustomer',
};
