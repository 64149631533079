import {connect} from "react-redux";
import Registration from "./Registration";
import {getStationIdByOwnerUid} from "../../actions/getStationIdByOwnerUid";

const mapStateToProps = ({
    user: {user}
                         }) => ({
    user
});

export default connect(
    mapStateToProps,
    {getStationIdByOwnerUid}
)(Registration);
