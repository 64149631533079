import React, {useState} from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {isValidStationFilter} from "../../lib/regexp";
import styles from "./styles.module.scss";

const ModalAddFilter = ({
                            close,
                            onSave,
                        }) => {
    const [filter, setFilter] = useState("");
    const handleInput = event => setFilter(event.target.value);
    const isInvalidFilter = !isValidStationFilter(filter);
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Add new filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Filter always should have format </p>
                <i>{'{8 numbers or Latin letters}-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}</i>
                <p className={"mb-3"}>Just input first 8 symbols for saving the correct filter</p>
                <Form.Control type="text" onChange={handleInput} value={filter}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={close}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        onSave(filter);
                        close();
                    }}
                    disabled={isInvalidFilter}
                >
                    Save
                </Button>
            </Modal.Footer>
        </>
    )
};

ModalAddFilter.propTypes = {
    close: PropTypes.func,
    onSave: PropTypes.func.isRequired,
};

export default ModalAddFilter;
