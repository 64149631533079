import {functions} from "../../lib/firebase";
import {functions as F} from "../../constants/functions";
import {actions as A} from "../../constants/actions";

export const checkAndCreateCustomer = () => {
    return (dispatch) => {
        dispatch({
            type: A.CHECK_CUSTOMER
        });
        const checkAndCreateCustomer = functions.httpsCallable(F.CHECK_AND_CREATE_CUSTOMER);
        return checkAndCreateCustomer({})
            .then(response => {
                const {url} = response.data;
                dispatch({
                    type: A.CHECK_CUSTOMER_SUCCESS,
                    payload: {url}
                });

                return url
            })
            .catch((err) => {
                return dispatch({
                    type: A.CHECK_CUSTOMER_FAILURE,
                    payload: {errorMessage: err.message}
                })
            });
    };
};
