import {functions} from "../lib/firebase";
import {functions as F} from "../constants/functions";
import {actions as A} from "../constants/actions";

export const getStationIdByOwnerUid = uid => {
    return (dispatch) => {
        dispatch({
            type: A.GET_STATION_ID_BY_HASH
        });
        const getStationIdByOwnerUid = functions.httpsCallable(F.GET_STATION_ID_BY_OWNER_UID);

        return getStationIdByOwnerUid({uid})
            .then(response => {
                const {stationId, hash} = response.data;
                dispatch({
                    type: A.CHECK_STATION_ID_SUCCESS,
                    payload: {stationId, hash}
                })
            })
            .catch((err) => {
                dispatch({
                    type: A.CHECK_STATION_ID_FAILURE,
                    payload: {errorMessage: err.message}
                })
            });
    };
};
