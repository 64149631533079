import React from 'react';
import {NavLink} from 'react-router-dom';
import check from '../../../assets/landing/check-circle.svg';
import enter from '../../../assets/landing/enter.svg';
import img from '../../../assets/landing/img-section-3.jpg';
import arrow from '../../../assets/landing/arrow-small.svg';
import {routes as R} from '../../../constants/routes';
import styles from './styles.module.scss';

const KeepYourTeamsTitle = () => (
    <h2>Keep your teams safe.</h2>
);

const KeepYourTeamsText = () => (
    <p>Digital contact tracing is proving to be a critical part of keeping our communities safe.</p>
);

const HeaderWhatIs = () => (
    <h2>
        <span className={styles.bold}>What</span> Trace Check-In is
    </h2>
);
const WhatIsText1 = () => (
    <p>
        A tablet that
        <span className={styles.bold}>
            verifies the use of Apple & Google’s Exposure Notification system
        </span>
        on smartphones
    </p>
);
const WhatIsText2 = () => (
    <p>
        Currently designed to be part of, and support,
        <span className={styles.bold}>entry protocols</span>
        at reception and common areas.
    </p>
);

const GetStarted = () => (
    <NavLink
        to={R.STORE}
        className={styles.getStarted}
    >
        <span>Get started</span>
        <img src={arrow} alt="arrow"/>
    </NavLink>
);

const WhatIs = () => (
    <section className={styles.whatIsContainer}>
        <div className={styles.keepYouTeams}>
            <KeepYourTeamsTitle/>
            <KeepYourTeamsText/>
        </div>
        <div className={styles.whiteContainer}>
            <div className={styles.col}>
                <HeaderWhatIs/>
                <div className={styles.row}>
                    <div className={styles.circleBg}>
                        <img src={check} alt="check"/>
                    </div>
                    <WhatIsText1/>
                </div>
                <div className={styles.row}>
                    <div className={styles.circleBg}>
                        <img src={enter} alt="enter"/>
                    </div>
                    <WhatIsText2/>
                </div>
            </div>
            <GetStarted/>
            <div className={styles.col}>
                <img src={img} alt="img"/>
            </div>
        </div>
    </section>
);

export default WhatIs;
