import React from 'react';
import {
    Route,
    Redirect,
} from "react-router-dom";
import {routes as R} from "../../constants/routes";
import Registration from "../../pages/Registration";
import Logs from "../../pages/Logs";
import Logout from "../../components/Logout";
import Account from "../../pages/Account";

const UserRoutes = () => (
    <>
        <Route exact path={R.REGISTRATION} component={Registration} />
        <Route exact path={R.LOGS} component={Logs} />
        <Route exact path={R.LOGOUT} component={Logout}/>
        <Route exact path={R.ACCOUNT} component={Account} />
    </>
);

export default UserRoutes;
