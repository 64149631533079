import React from 'react';
import FileContent from '../../components/FileContent';
import policy from '../../terms/TCI.Privacy.Policy.docx';
import styles from './styles.module.scss';

const PrivacyPolicy = () => (
    <FileContent filePath={policy} className={styles.policy}/>
);

export default PrivacyPolicy;
