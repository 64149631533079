import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormStationSettings from "../../components/FormStationSettings";
import styles from "./styles.module.scss";

const PanelStationInfoView = ({
                         stationId,
                         hash,
                         isActive
                     }) => (
    <>
        <Card className={"w-100 mb-3"}>
            <Card.Header>
                            <h2 className={styles.station}>
                                Station
                                {(isActive)
                                    ? <Badge
                                        className={styles.badge}
                                        variant="secondary"
                                    >
                                        Active
                                    </Badge>
                                    : <Badge
                                        className={styles.badge}
                                        variant="danger"
                                    >
                                        Not active
                                    </Badge>}
                            </h2>
            </Card.Header>
            <Card.Body className={styles.settingItem}>
                <Container className={'container-xl p-0 m-0'}>
                    <Row md>
                        <Col md={3} className={styles.settingItem}>
                            Id:
                        </Col>
                        <Col className={styles.settingItem}>
                            {stationId}
                        </Col>
                    </Row>
                    <Row md>
                        <Col md={3} className={styles.settingItem}>
                            Hash:
                        </Col>
                        <Col className={styles.settingItem}>
                            {hash}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
        <Card className={"w-100 mb-3"}>
            <Card.Header><h2>Station Settings</h2></Card.Header>
            <Card.Body>
                <FormStationSettings/>
            </Card.Body>

        </Card>
    </>
);

PanelStationInfoView.propTypes = {
    stationId: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    selectStation: PropTypes.func,
    isActive: PropTypes.bool
};

export default PanelStationInfoView;
