import React from 'react';
import styles from './styles.module.scss';

const Components = () => (
    <section className={styles.componentsContainer}>
        {/*background image*/}
    </section>
);

export default Components;
