import {loadStripe} from "@stripe/stripe-js";
import stripeConfig from '../../config/stripe';

//createSession passed as a function argument because it needs to connect with redux dispatch;
export const handleCheckout = ({cart, createSession, customerId = null, mode = 'payment'}) => {
    return async dispatch => {
        const {mode: stripeMode} = stripeConfig;
        const stripePromise = loadStripe(stripeConfig[stripeMode].public_key);
        const stripe = await stripePromise;
        const session = await createSession({cart, customerId, mode});
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.

        }
    };
};
