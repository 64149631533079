import React from "react";
import PropTypes from "prop-types";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import ButtonWithModal from "../ButtonWithModal";
import ModalAddFilter from "../ModalAddFilter/ModalAddFilter";
import AlertError from "../AlertError";
import plus from '../../assets/iconfinder_ic_control_point_48px_352288.svg';
import cross from '../../assets/iconfinder_ic_highlight_remove_48px_352425.svg';
import styles from "./styles.module.scss";

const FormStationSettingsView = ({
                                     activeRssi,
                                     onRssiChange,
                                     loggingPeriod,
                                     onPeriodChange,
                                     scanFilters,
                                     onRemoveFilter,
                                     onAddFilter,
                                     highRssi,
                                     onHighRssiChange,
                                     highRssiOffset,
                                     onHighRssiOffsetChange,
                                     isFaceDetectionEnabled,
                                     onFaceDetectionChange,
                                     onSave,
                                     onReset,
                                     canSave,
                                     loading,
                                     error,
                                     errorMessage
                                 }) => (
    <Form>
        <Form.Row>
            <Form.Group as={Col} sm>
                <Form.Label className={styles.settingItem}>Device Sensitivity:</Form.Label>
            </Form.Group>
            <Form.Group as={Col} sm>
                <Form.Control
                    type="number"
                    max={-26}
                    min={-100}
                    step={1}
                    value={activeRssi}
                    onChange={onRssiChange}
                />
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col} sm>
                <Form.Label className={styles.settingItem}>High RSSI:</Form.Label>
            </Form.Group>
            <Form.Group as={Col} sm>
                <Form.Control
                    type="number"
                    max={-26}
                    min={-100}
                    step={1}
                    value={highRssi}
                    onChange={onHighRssiChange}
                />
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col} sm>
                <Form.Label className={styles.settingItem}>High RSSI Offset:</Form.Label>
            </Form.Group>
            <Form.Group as={Col} sm>
                <Form.Control
                    type="number"
                    step={1}
                    value={highRssiOffset}
                    onChange={onHighRssiOffsetChange}
                />
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label className={styles.settingItem}>Face detection enabled:</Form.Label>
            </Form.Group>

            <Form.Group as={Col}>
                <Form.Check
                    type="checkbox"
                    className={styles.checkbox}
                    checked={isFaceDetectionEnabled}
                    aria-label="face detection enabled"
                    onChange={onFaceDetectionChange}
                />
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col} sm>
                <Form.Label className={styles.settingItem}>Logging period, ms:</Form.Label>
            </Form.Group>

            <Form.Group as={Col} sm>
                <Form.Control
                    type="number"
                    min={0}
                    step={1000}
                    value={loggingPeriod}
                    onChange={onPeriodChange}
                />
            </Form.Group>
        </Form.Row>
        <Form.Group>
            <Form.Label>Scan filters:</Form.Label>
            {scanFilters.map((filter, ind) => (
                <Form.Row key={ind}>
                    <Form.Group as={Col} sm>
                        <Form.Control type="text" value={filter} readOnly/>
                    </Form.Group>

                    <Form.Group as={Col} sm>
                        <Button
                            variant="outline-danger"
                            onClick={() => onRemoveFilter(filter)}
                            className={styles.btnRemove}
                        >
                            <div className={styles.crossImg}/>
                            <div>Remove</div>
                        </Button>
                    </Form.Group>
                </Form.Row>
            ))}
            <ButtonWithModal
                variant="outline-success"
                label={(
                    <>
                        <div className={styles.plusImg}/>
                        <div>Add</div>
                    </>
                )}
                labelClassName={styles.btnAdd}
            >
                <ModalAddFilter onSave={onAddFilter}/>
            </ButtonWithModal>
        </Form.Group>
        <ButtonGroup className={"w-50 mr-auto ml-auto mb-2"}>
            <Button
                variant="primary"
                className={`${styles.btnSave} mr-2`}
                onClick={onSave}
                disabled={!canSave}
            >
                Save
            </Button>
            <Button
                variant="outline-primary"
                className={`${styles.btnReset} mr-2`}
                onClick={onReset}
            >
                Reset
            </Button>
        </ButtonGroup>
        <div className={"mt-2"}>
            {loading && <Spinner animation="border" variant="primary"/>}
            {error && <AlertError message={errorMessage}/>}
        </div>
    </Form>
);

FormStationSettingsView.propTypes = {
    activeRssi: PropTypes.number,
    onRssiChange: PropTypes.func.isRequired,
    loggingPeriod: PropTypes.number,
    onPeriodChange: PropTypes.func.isRequired,
    scanFilters: PropTypes.array.isRequired,
    onRemoveFilter: PropTypes.func.isRequired,
    onAddFilter: PropTypes.func.isRequired,
    highRssi: PropTypes.number,
    onHighRssiChange: PropTypes.func.isRequired,
    highRssiOffset: PropTypes.number,
    onHighRssiOffsetChange: PropTypes.func.isRequired,
    isFaceDetectionEnabled: PropTypes.bool,
    onFaceDetectionChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    canSave: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
};

export default FormStationSettingsView;
