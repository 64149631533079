import React from 'react';
import {NavLink} from 'react-router-dom';
import logo from '../../assets/landing/steelcase-logo.svg';
import {routes as R} from '../../constants/routes';
import styles from './styles.module.scss';

const traceCheckIn = 'Trace Check-In';
const menu = [
    {
        id: 1,
        label: 'Account',
        route: R.ACCOUNT,
    },
    {
        id: 2,
        label: 'Store',
        route: R.STORE,
    },
    {
        id: 3,
        label: 'Help',
        route: R.HELP_CENTER,
    },
    {
        id: 4,
        label: 'Terms & Conditions',
        route: R.TERMS_OF_SERVICE,
    },
    {
        id: 5,
        label: 'Privacy Policy',
        route: R.PRIVACY_POLICY,
    },
];
const copyrights = '© 2021 Steelcase Inc. All rights reserved';

const Footer = () => (
    <footer className={styles.footerContainer}>
        <h2><NavLink to={R.HOME}>{traceCheckIn}</NavLink></h2>
        <menu className={styles.footerMenu}>
            {
                menu.map(item => {
                    const {id, label, route} = item;
                    return (
                        <NavLink
                            key={id}
                            to={route}
                            className={styles.menuItem}
                        >
                            {label}
                        </NavLink>
                    )
                })
            }
        </menu>
        <div className={styles.divider} />
        <div className={styles.bottom}>
            <div className={styles.copyrights}>{copyrights}</div>
            <img src={logo} alt="Steelcase logo"/>
        </div>
    </footer>
);

export default Footer;
