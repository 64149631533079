import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import TabControlLogsView from "./TabControlLogs-view";
import {getMaximalTime, getMinimalTime} from "../../lib/summary";
import {
    roundHoursToNext,
    roundHoursToPrev
} from "../../lib/utils/timestamp";
import {dashboardFilters as F} from "../../constants/dashboardFilters";

const defaultState = {
    startDate: new Date(),
    endDate: new Date(),
    filter: 'all'
};

class TabControlLogs extends Component {
    state = defaultState;

    componentDidMount() {
        this.updateRange();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.logs.length !== this.props.logs.length) {
            this.updateRange();
        }
    }

    handleStartDateChange = (startDate) => this.setState({startDate});
    handleEndDateChange = (endDate) => this.setState({endDate});
    handleFilterChange = (filter) => this.setState({filter});

    updateRange = () => {
        const {logs} = this.props;
        const startDate = roundHoursToPrev(getMinimalTime(logs).toDate());
        const endDate = roundHoursToNext(getMaximalTime(logs).toDate());
        this.setState({startDate, endDate});
    };

    filterLogs = (logs) => {
        const {startDate, endDate} = this.state;
        return logs.filter(log => {
            return moment(log.timestamp).isBetween(startDate, endDate);
        });
    };

    filterData = (data) => {
        const {startDate, endDate} = this.state;

        return data.filter(item => {
            return moment(item.x).isBetween(startDate, endDate);
        });
    };

    filterSeries = (series) => {
        const filteredSeries = {};
        for (let key in series) {
            if (series.hasOwnProperty(key)) {
                filteredSeries[key] = series[key].map(item => {
                    return {
                        ...item,
                        data: this.filterData(item.data)
                    }
                })
            }
        }

        return filteredSeries;
    };

    getFilteredLogsAndData = () => {
        const {filter} = this.state;
        const {logs, data: series} = this.props;
        return (filter === F.range)
            ? {logs: this.filterLogs(logs), data: this.filterSeries(series)}
            : {logs, data: series}
    };

    render() {
        const {logs, data} = this.getFilteredLogsAndData();
        const {startDate, endDate, filter} = this.state;
        return (
            <TabControlLogsView
                logs={logs}
                data={data}
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={this.handleStartDateChange}
                onEndDateChange={this.handleEndDateChange}
                filter={filter}
                onFilterChange={this.handleFilterChange}
            />
        )
    }
}

TabControlLogs.propTypes = {
    logs: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
};

export default TabControlLogs;
