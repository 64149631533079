import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts"
import styles from "./styles.module.scss";

const ChartPieView = ({
    title,
    options,
    series
                      }) => (
    <div className={styles.chartContainer}>
        <h3 className={styles.title}>{title}</h3>
        <ReactApexChart options={options} series={series} type="pie"/>
    </div>
);

ChartPieView.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
    series: PropTypes.array.isRequired

};

export default ChartPieView;
