import {connect} from 'react-redux';
import Checkout from './Checkout';
import {createSession} from '../../actions/stripe/createSession';
import {handleCheckout} from '../../actions/stripe/handleCheckout';

const mapStateToProps = ({
                             cart: {products},
                             products: {products: productList},
                             stripeCustomer: {customerId}
                         }) => ({
    products,
    productList,
    customerId
});

export default connect(
    mapStateToProps,
    {createSession, handleCheckout}
)(Checkout);
