import React, {Component} from "react";
import PropTypes from "prop-types";
import PanelStationInfoView from "./PanelStationInfo-view";
import {routerTypes} from "../../types/routerTypes";


class PanelStationInfo extends Component {
    render() {
        const {stationId, hash, isActive} = this.props;
        return (
            <PanelStationInfoView
                stationId={stationId}
                hash={hash}
                isActive={isActive}
            />
        )
    }
}

PanelStationInfo.propTypes = {
    ...routerTypes,
    stationId: PropTypes.string,
    hash: PropTypes.string,
    isActive: PropTypes.bool
};

export default PanelStationInfo;
