import React, {Component} from "react";
import { Provider } from "react-redux";
import {store} from "../../store/configureStore";

function withRedux(WrappedComponent) {
    return class extends Component {
        render() {

            return (
                <Provider store={store}>
                    <WrappedComponent {...this.props}/>
                </Provider>
            );
        }
    }
}

export default withRedux
