import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';
import {NavLink} from "react-router-dom";
import {routes as R} from "../../constants/routes";
import cartIcon
    from '../../assets/iconfinder_ic_local_grocery_store_48px_352500.svg';
import styles from './styles.module.scss';

const CartIcon = ({products}) => {
    const totalProducts = Object.keys(products).length;
    return (
        <NavLink to={R.STORE_CART}>
            <div className={styles.cartIcon}>
                <img src={cartIcon} alt="cart icon" className={styles.icon}/>
                <Badge
                    variant="secondary"
                    pill
                    className={styles.total}
                >
                    {totalProducts}
                </Badge>
            </div>
        </NavLink>
    )
};

CartIcon.propTypes = {};

export default CartIcon;
