import React, {Component} from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import {auth} from "../../lib/firebase";
import LoginView from "./Login-view";
import {routerTypes} from "../../types/routerTypes";
import {loginState} from "../../constants/login";
import {routes as R} from "../../constants/routes";

const signInTitle = 'Sign in';
const signInSubTitle = 'Enter your email to create a new account or sign in to an existing account';
const resetPwdTitle = 'Reset your password';
const resetPwdText = 'Enter your email below to receive instructions on how to reset your password';

const defaultState = {
    login: "",
    isValid: false,
    state: null
};

const changeSignInLabels = () => {
    const firebaseTitle = document.querySelector('.firebaseui-id-page-sign-in .firebaseui-title');
    firebaseTitle.innerText = signInTitle;

    const firebaseSubTitle = document.createElement('h2');
    firebaseSubTitle.classList.add('firebaseui-sub-title');
    firebaseSubTitle.innerText = signInSubTitle;
    const firebaseCardHeader = document.querySelector('.firebaseui-id-page-sign-in .firebaseui-card-header');
    firebaseCardHeader.append(firebaseSubTitle);
};

const changeResetPasswordLabels = () => {
    const resetPasswordTitle = document.querySelector('.firebaseui-id-page-password-recovery .firebaseui-title');
    if (resetPasswordTitle && resetPasswordTitle.innerText !== resetPwdTitle) {
        resetPasswordTitle.innerText = resetPwdTitle;
    }

    const resetPasswordText = document.querySelector('.firebaseui-id-page-password-recovery .firebaseui-text');
    if (resetPasswordText && resetPasswordText.innerText !== resetPwdText) {
        resetPasswordText.innerText = resetPwdText;
    }
};


class Login extends Component {
    state = defaultState;

    uiConfig = {
        callbacks: {
            uiShown: () => {
                changeSignInLabels();

                const targetNode = document.getElementById('firebaseui_container');
                const config = {
                    attributes: true,
                    childList: true,
                    subtree: true
                };
                const observer = new MutationObserver(() => {
                    changeResetPasswordLabels();
                });
                observer.observe(targetNode, config);
            },
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                const {history, setAuthSuccess} = this.props;
                const {
                    uid,
                    email,
                    displayName: name
                } = authResult.user;

                if (!auth.currentUser.emailVerified) {
                    auth.currentUser.sendEmailVerification().then(function () {
                        console.log('verification message was sent, pleas check your email');
                    }).catch(function (error) {
                        console.error({error});
                    });
                }

                firebase.auth().onAuthStateChanged(function (user) {
                    if (user) {
                        // User is signed in.
                        setAuthSuccess(user);
                    } else {
                        // No user is signed in.
                    }
                });

                // setAuthSuccess({uid, email, name, emailVerified: auth.currentUser.emailVerified});
                history.push(R.ACCOUNT);
            },
            signInFailure: error => {
                return this.handleAuthError(error);
            }
        },
        signInFlow: 'popup',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID
        ]
    };

    /*componentDidMount() {
        this.updateState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const stationChanged = this.props.stationId !== prevProps.stationId;
        const loadingChanged = this.props.loading !== prevProps.loading;
        const errorChanged = this.props.error !== prevProps.error;
        if (stationChanged || loadingChanged || errorChanged) {
            this.updateState();
        }
    }*/

    handleAuthError = err => {
        console.log(err);
        this.props.setAuthError(err);
    };

    updateState = () => {
        const {stationId, error, loading} = this.props;
        let state = null;
        if (stationId) {
            state = loginState.ok;
        } else if (loading) {
            state = loginState.loading;
        } else if (error) {
            state = loginState.error
        }
        this.setState({state});
    };

    render() {
        const {errorMessage} = this.props;
        const {state} = this.state;
        return (
            <LoginView
                state={state}
                errorMessage={errorMessage}
                uiConfig={this.uiConfig}
                auth={auth}
            />
        )
    }
}

Login.propTypes = {
    ...routerTypes,
    ok: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    stationId: PropTypes.string,
    getStationIdByHash: PropTypes.func,
    checkStationId: PropTypes.func,
    setStationId: PropTypes.func,
    setAuthError: PropTypes.func,
    setAuthSuccess: PropTypes.func,
};

export default Login;
