import React, {Component} from "react";
import PropTypes from "prop-types";
import RegistrationView from "./Registration-view";
import {registerStationByCode} from "../../api/registerCode";
import {routerTypes} from "../../types/routerTypes";

const defaultState = {
    code: "",
    showError: false,
    messageError: "Ooops!...",
    showSuccess: false,
    messageSuccess: "Station was successfully registered",
    showSpinner: false,
    isDisabled: true
};

class Registration extends Component {
    state = defaultState;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.code !== prevState.code) {
            this.verifyCode();
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({showSpinner: true}, this.sendCode);
    };

    handleCodeChange = (event) => {
        const code = event.target.value;
        if (/\d/.test(code) || code.length === 0)
            this.setState({
                code,
                showError: false,
                showSuccess: false
            });
    };

    verifyCode = () => {
        const {code} = this.state;
        if (code.length === 6) this.setState({isDisabled: false});
        else this.setState({isDisabled: true});
    };

    sendCode = () => {
        const {user: {uid, email, displayName: name}} = this.props;
        registerStationByCode({
            code: this.state.code,
            owner: {uid, email, name}
        })
            .then(response => {
                this.setState({showSpinner: false});
                return response;
            })
            .then(({error, ok}) => {
                if (error) throw new Error(error);
                this.setState({
                    showSuccess: ok,
                    showError: false
                })
            })
            .then(() => {
                const {user: {uid}, getStationIdByOwnerUid} = this.props;
                getStationIdByOwnerUid(uid)
            })
            .catch(err => {
                this.setState({
                    showSuccess: false,
                    showError: true,
                    messageError: err.message
                })
            })
            .finally(() => {
                this.setState({
                    code: "",
                    isDisabled: true
                })
            })
    };

    render() {
        const {
            code,
            messageError,
            showError,
            messageSuccess,
            showSuccess,
            showSpinner,
            isDisabled
        } = this.state;
        return (
            <RegistrationView
                onSubmit={this.handleSubmit}
                onCodeChange={this.handleCodeChange}
                code={code}
                isDisabled={isDisabled}
                messageError={messageError}
                showError={showError}
                messageSuccess={messageSuccess}
                showSuccess={showSuccess}
                showSpinner={showSpinner}
            />
        )
    }
}

Registration.propTypes = {
    ...routerTypes,
    user: PropTypes.object,
};

export default Registration;
