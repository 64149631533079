import {actions as A} from "../constants/actions";

const defaultState = {
    products: null,
    loading: true,
    error: false,
    errorMessage: ''
};

export default (state = defaultState, {type, payload}) => {
    switch(type) {
        case A.GET_PRODUCTS:
            return {...state};

        case A.GET_PRODUCTS_SUCCESS:
            const {products} = payload;
            return {...state, products, loading: false};

        case A.GET_PRODUCTS_FAILURE:
            const {errorMessage} = payload;
            return {...state, errorMessage, error: true, loading: false};

        default:
            return state;
    }
}
