import {actions as A} from "../constants/actions";
import {cookies as K} from "../constants/cookies";
import {setCookie} from "../lib/utils/cookies";

const defaultState = {
    stations: null,
    loading: false,
    error: false,
    errorMessage: ''
};

export default (state = defaultState, {type, payload}) => {
    switch(type) {
        case A.GET_STATIONS_BY_OWNER:
            return {...state, loading: true};

        case A.GET_STATIONS_BY_OWNER_SUCCESS:
            const {stations} = payload;
            setCookie(K.stations, stations);
            return {...state, stations, loading: false};

        case A.GET_STATIONS_BY_OWNER_FAILURE:
            const {errorMessage} = payload;
            return {...state, errorMessage, error: true, loading: false};

        case A.LOG_OUT:
            return {...defaultState};

        default:
            return state;
    }
}
