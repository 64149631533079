import {connect} from 'react-redux';
import Account from './Account';
import {logOut} from "../../actions/logOut";
import {createPortalSession} from "../../actions/stripe/createPortalSession";

const mapStateToProps = ({
    user: {user},
    stripeCustomer: {customerId},
    stripePortalSession: {url}
                         }) => ({
    user,
    customerId,
    url
});

export default connect(
    mapStateToProps,
    {logOut, createPortalSession}
)(Account);
