import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {Button} from "react-bootstrap";
import {routes as R} from "../../../constants/routes";
import accountIcon from '../../../assets/iconfinder_ic_account_circle_48px_3669480.svg';
import styles from "../styles.module.scss";

const MenuTransparentBg = ({isLogged}) => (
    <div className={`${styles.linksOnRight} d-flex`}>
        {
            (isLogged)
                ? (
                    <NavLink
                        to={R.ACCOUNT}
                        className={`nav-link ${styles.account}`}
                    >
                        <img src={accountIcon} alt="acc"/>
                        <span>Account</span>
                    </NavLink>
                )
                : (
                    <>
                        <NavLink
                            to={R.LOGIN}
                            className={`nav-link ${styles.landingButton} ${styles.landingButtonOutline} ${styles.landingLogin}`}
                        >
                            Login
                        </NavLink>
                        <NavLink
                            to={R.LOGIN}
                            className={`nav-link ${styles.landingButton}`}
                        >
                            Sign up
                        </NavLink>
                    </>
                )
        }

    </div>
);

export default MenuTransparentBg;
