import React from "react";
import PropTypes from "prop-types";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import styles from "./styles.module.scss";
import Spinner from "react-bootstrap/Spinner";

const title = 'Register your device';
const text = 'Please enter your six-digit registration code:';

const RegistrationView = ({
                              onSubmit,
                              onCodeChange,
                              code,
                              isDisabled,
                              showSuccess,
                              messageSuccess,
                              showError,
                              messageError,
                              showSpinner
                          }) => (
    <React.Fragment>
        <h1 className={styles.title}>{title}</h1>
        <Form onSubmit={onSubmit} className={styles.form}>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>{text}</Form.Label>
                <Form.Control
                    type="text"
                    maxLength={6}
                    placeholder="Enter code"
                    onChange={onCodeChange}
                    value={code}
                />
            </Form.Group>
            <Button
                variant="primary"
                type="submit"
                className={styles.submitBtn}
                disabled={isDisabled}
            >
                Register
            </Button>
        </Form>
        <div className={styles.alerts}>
            <Spinner
                animation="border"
                variant="primary"
                role="status"
                className={styles.spinner}
                hidden={!showSpinner}
            >
                <span className="sr-only">Loading...</span>
            </Spinner>
            <Alert variant="success" hidden={!showSuccess}>
                {messageSuccess}
            </Alert>
            <Alert variant="danger" hidden={!showError}>{messageError}</Alert>
        </div>
    </React.Fragment>
);

RegistrationView.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCodeChange: PropTypes.func.isRequired,
    code: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    showSuccess: PropTypes.bool.isRequired,
    messageSuccess: PropTypes.string.isRequired,
    showError: PropTypes.bool.isRequired,
    messageError: PropTypes.string.isRequired,
    showSpinner: PropTypes.bool.isRequired,
};

export default RegistrationView;
