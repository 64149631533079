import {connect} from "react-redux";
import Header from "./Header";

const mapStateToProps = ({
                             station: {stationId},
                             user: {user}
                         }) => ({
    stationId,
    user
});

export default connect(
    mapStateToProps,
    null
)(Header);
