import React from 'react';
import Jumbotron from './Jumbotron';
import About from './About';
import WhatIs from './WhatIs';
import Components from './Components';
import Why from './Why';
import Timing from './Timing';
import Process from './Process';
import ProcessMobile from './ProcessMobile';
import Support from './Support';
import styles from './styles.module.scss';

const Landing = () => (
    <main className={styles.landingContainer}>
        <Jumbotron/>
        <About/>
        <WhatIs/>
        <Components/>
        <Why/>
        <Timing/>
        <Process/>
        <ProcessMobile/>
        <Support/>
    </main>
);

export default Landing;
