import {functions} from "../lib/firebase";
import {functions as F} from "../constants/functions";
import {actions as A} from "../constants/actions";

export const getSettingsByStationId = stationId => {
    return (dispatch) => {
        dispatch({
            type: A.GET_SETTINGS_BY_STATION_ID
        });
        const getSettingsByStationId = functions.httpsCallable(F.GET_SETTINGS_BY_STATION_ID);

        return getSettingsByStationId({stationId})
            .then(response => {
                dispatch({
                    type: A.GET_SETTINGS_BY_STATION_ID_SUCCESS,
                    payload: {settings: response.data.settings || {}}
                })
            })
            .catch((err) => {
                dispatch({
                    type: A.GET_SETTINGS_BY_STATION_ID_FAILURE,
                    payload: {errorMessage: err.message}
                })
            });
    };
};
