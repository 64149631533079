export const actions = {
    CART_EMPTY: "cart/EMPTY", //react-shopping-cart action
    AUTH_BY_STATION_ID: "AUTH_BY_STATION_ID",
    AUTH_BY_STATION_ID_SUCCESS: "AUTH_BY_STATION_ID_SUCCESS",
    AUTH_BY_STATION_ID_FAILURE: "AUTH_BY_STATION_ID_FAILURE",
    AUTH_WITH_FIREBASE: "AUTH_WITH_FIREBASE",
    AUTH_WITH_FIREBASE_SUCCESS: "AUTH_WITH_FIREBASE_SUCCESS",
    AUTH_WITH_FIREBASE_FAILURE: "AUTH_WITH_FIREBASE_FAILURE",
    SET_EMAIL_VERIFIED: "SET_EMAIL_VERIFIED",
    SET_USER: "SET_USER",
    SIGN_IN_WITH_TOKEN: "SIGN_IN_WITH_TOKEN",
    SIGN_IN_WITH_TOKEN_SUCCESS: "SIGN_IN_WITH_TOKEN_SUCCESS",
    SIGN_IN_WITH_TOKEN_FAILURE: "SIGN_IN_WITH_TOKEN_FAILURE",
    LOG_OUT: "LOG_OUT",
    GET_LOGS: "GET_LOGS",
    GET_LOGS_SUCCESS: "GET_LOGS_SUCCESS",
    GET_LOGS_FAILURE: "GET_LOGS_FAILURE",
    GET_HMAC_BY_EMAIL: "GET_HMAC_BY_EMAIL",
    GET_HMAC_BY_EMAIL_SUCCESS: "GET_HMAC_BY_EMAIL_SUCCESS",
    GET_HMAC_BY_EMAIL_FAILURE: "GET_HMAC_BY_EMAIL_FAILURE",
    REGISTER_STATION_BY_CODE: "REGISTER_STATION_BY_CODE",
    REGISTER_STATION_BY_CODE_SUCCESS: "REGISTER_STATION_BY_CODE_SUCCESS",
    REGISTER_STATION_BY_CODE_FAILURE: "REGISTER_STATION_BY_CODE_FAILURE",
    GET_STATION_ID_BY_HASH: "GET_STATION_ID_BY_HASH",
    GET_STATION_ID_BY_HASH_SUCCESS: "GET_STATION_ID_BY_HASH_SUCCESS",
    GET_STATION_ID_BY_HASH_FAILURE: "GET_STATION_ID_BY_HASH_FAILURE",
    GET_STATIONS_BY_OWNER: "GET_STATIONS_BY_OWNER",
    GET_STATIONS_BY_OWNER_SUCCESS: "GET_STATIONS_BY_OWNER_SUCCESS",
    GET_STATIONS_BY_OWNER_FAILURE: "GET_STATIONS_BY_OWNER_FAILURE",
    CHECK_STATION_ID: "CHECK_STATION_ID",
    CHECK_STATION_ID_SUCCESS: "CHECK_STATION_ID_SUCCESS",
    CHECK_STATION_ID_FAILURE: "CHECK_STATION_ID_FAILURE",
    SET_STATION_ID: "SET_STATION_ID",
    GET_SETTINGS_BY_STATION_ID: "GET_SETTINGS_BY_STATION_ID",
    GET_SETTINGS_BY_STATION_ID_SUCCESS: "GET_SETTINGS_BY_STATION_ID_SUCCESS",
    GET_SETTINGS_BY_STATION_ID_FAILURE: "GET_STATION_ID_BY_HASH_FAILURE",
    SET_SETTINGS_FOR_STATION_ID: "SET_SETTINGS_FOR_STATION_ID",
    SET_SETTINGS_FOR_STATION_ID_SUCCESS: "SET_SETTINGS_FOR_STATION_ID_SUCCESS",
    SET_SETTINGS_FOR_STATION_ID_FAILURE: "SET_SETTINGS_FOR_STATION_ID_FAILURE",
    CREATE_SESSION: "CREATE_SESSION",
    CREATE_SESSION_SUCCESS: "CREATE_SESSION_SUCCESS",
    CREATE_SESSION_FAILURE: "CREATE_SESSION_FAILURE",
    GET_CUSTOMER_ID: "GET_CUSTOMER_ID",
    GET_CUSTOMER_ID_SUCCESS: "GET_CUSTOMER_ID_SUCCESS",
    GET_CUSTOMER_ID_FAILURE: "GET_CUSTOMER_ID_FAILURE",
    CREATE_PORTAL_SESSION: "CREATE_PORTAL_SESSION",
    CREATE_PORTAL_SESSION_SUCCESS: "CREATE_PORTAL_SESSION_SUCCESS",
    CREATE_PORTAL_SESSION_FAILURE: "CREATE_PORTAL_SESSION_FAILURE",
    GET_PRODUCTS: "GET_PRODUCTS",
    GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
    GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",
    CHECK_CUSTOMER: "CHECK_CUSTOMER",
    CHECK_CUSTOMER_SUCCESS: "CHECK_CUSTOMER_SUCCESS",
    CHECK_CUSTOMER_FAILURE: "CHECK_CUSTOMER_FAILURE",
};
