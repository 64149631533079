import React from 'react';
import {NavLink} from "react-router-dom";
import {routes as R} from "../../../constants/routes";
import styles from './styles.module.scss';

const header = 'Support digital contact tracing in your workplace';
const labelGetStarted = 'Get started';

const Support = () => (
    <section className={styles.supportContainer}>
        <h2>{header}</h2>
        <NavLink
            to={R.STORE}
            className={styles.button}
        >
            {labelGetStarted}
        </NavLink>
    </section>
);

export default Support;
