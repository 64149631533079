import React from "react";
import PropTypes from "prop-types";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import PanelSummary from "../PanelSummary";
import TableLogs from "../../components/TableLogs";
import Filter from "./Filter";
import AlertWarning from "../AlertWarning";
import {dashboardFilters as F} from "../../constants/dashboardFilters";
import styles from "./styles.module.scss";
import PanelGraphs from "../PanelGraphs";

const datepickerOpts = {
    todayButton: "Today",
    showTimeSelect: true,
    timeFormat: "HH:mm",
    timeIntervals: 15,
    timeCaption: "time",
    dateFormat: "dd/MMMM/yyyy HH:mm",
    popperModifiers: {
        preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport"
        }
    }
};

const TabControlLogsView = ({
                                logs,
                                data,
                                startDate,
                                endDate,
                                onStartDateChange,
                                onEndDateChange,
                                filter,
                                onFilterChange
                            }) => (
    <div className={styles.container}>
        <ButtonToolbar
            className="mb-3 d-flex justify-content-start align-items-center w-100"
        >
            <h2 className="mr-3">Filter</h2>
            <Filter onChange={onFilterChange} filter={filter}/>
            <div className={
                `${(filter === F.all) ? styles.hidden : ""} ml-auto`
            }>
                <InputGroup className="mb-1">
                    <InputGroup.Prepend className={styles.inputLabel}>
                        <InputGroup.Text>Start date/time</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        as={DatePicker}
                        selected={startDate}
                        onChange={onStartDateChange}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        {...datepickerOpts}
                    />
                </InputGroup>
                <InputGroup>
                    <InputGroup.Prepend className={styles.inputLabel}>
                        <InputGroup.Text>End date/time</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        as={DatePicker}
                        selected={endDate}
                        onChange={onEndDateChange}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        {...datepickerOpts}
                    />
                </InputGroup>
            </div>
        </ButtonToolbar>
        <Tabs defaultActiveKey="summary" id="tab-logs">
            <Tab eventKey="summary" title="Dashboard">
                <div className={styles.tab}>
                    {
                        (logs.length > 0)
                            ? <PanelSummary logs={logs}/>
                            : <AlertWarning message={"Nothing to show..."}/>
                    }
                </div>
            </Tab>
            <Tab eventKey="graphs" title="Graphs">
                <div className={styles.tab}>
                    {
                        (logs.length > 0)
                            ? <PanelGraphs data={data} />
                            : <AlertWarning message={"Nothing to show..."}/>
                    }
                </div>
            </Tab>
            <Tab eventKey="logs" title="Logs">
                <div className={styles.tab}>
                    {
                        (logs.length > 0)
                            ? <TableLogs logs={logs}/>
                            : <AlertWarning message={"Nothing to show..."}/>
                    }
                </div>
            </Tab>
        </Tabs>
    </div>
);

TabControlLogsView.propTypes = {
    logs: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    onStartDateChange: PropTypes.func.isRequired,
    onEndDateChange: PropTypes.func.isRequired,
    filter: PropTypes.string.isRequired,
    onFilterChange: PropTypes.func.isRequired
};

export default TabControlLogsView;
