import {compose} from 'redux';
import {withRouter} from "react-router-dom";
import withUser from "../../components/hoc/withUser";
import withScanResults from "../../components/hoc/withScanResults";
import withStation from "../../components/hoc/withStation";
import withVerifiedUser from "../../components/hoc/withVerifiedUser";
import Logs from "./Logs-redux";

export default compose(
    withRouter,
    withUser,
    withVerifiedUser,
    withStation,
    withScanResults
)(Logs);
