import moment from "moment";
import {Timestamp} from "../firebase";

export const getDateFromTimestamp = (timestamp) => {
    const {_seconds, _nanoseconds} = timestamp;
    return new Timestamp(_seconds, _nanoseconds).toDate();
};

export const getFormattedToLocaleDateFromTimestamp = (timestamp) => {
    const date = getDateFromTimestamp(timestamp);
    return date.toLocaleString();
};

export const getDateStringFromTimestamp = (timestamp) => {
    const date = getDateFromTimestamp(timestamp);
    return date.toString();
};

export const roundMinutes = (date) => {
    date.setHours(date.getHours() + Math.round(date.getMinutes()/60));
    date.setMinutes(0, 0, 0);

    return date;
};

export const roundHours = (date) => {
    date.setDate(date.getDate() + Math.round(date.getHours()/60));
    date.setHours(0, 0, 0, 0);

    return date;
};

export const roundHoursToNext = (date) => {
    date.setDate(date.getDate() + Math.round(date.getHours()/60) + 1);
    date.setHours(0, 0, 0, 0);

    return date;
};

export const roundHoursToPrev = (date) => {
    date.setDate(date.getDate() + Math.round(date.getHours()/60) - 1);
    date.setHours(0, 0, 0, 0);

    return date;
};

export const roundDates = (date) => {
    date.setMonth(date.getMonth() + Math.round(date.getDate()/60));
    date.setDate(1);
    date.setHours(0, 0, 0, 0);

    return date;
};

export const getTotalHoursBetweenDates = (date1, date2) => {
    return Math.abs(moment(date2).diff(moment(date1), 'hours'));
};

