import {functions} from "../lib/firebase";
import {functions as F} from "../constants/functions";
import {actions as A} from "../constants/actions";

export const getStationIdByHash = hash => {
    return (dispatch) => {
        dispatch({
            type: A.GET_STATION_ID_BY_HASH
        });
        const getStationIdByHash = functions.httpsCallable(F.GET_STATION_ID_BY_HASH);

        return getStationIdByHash({hash})
            .then(response => {
                dispatch({
                    type: A.CHECK_STATION_ID_SUCCESS,
                    payload: {stationId: response.data.stationId, hash}
                })
            })
            .catch((err) => {
                dispatch({
                    type: A.CHECK_STATION_ID_FAILURE,
                    payload: {errorMessage: err.message}
                })
            });
    };
};
