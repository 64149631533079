import {actions as A} from "../constants/actions";

const defaultState = {
    sessionId: null,
    loading: true,
    error: false,
    errorMessage: ''
};

export default (state = defaultState, {type, payload}) => {
    switch(type) {
        case A.CREATE_SESSION:
            return {...state, loading: true};

        case A.CREATE_SESSION_SUCCESS:
            const {sessionId} = payload;
            return {...state, sessionId, loading: false};

        case A.CREATE_SESSION_FAILURE:
            const {errorMessage} = payload;
            return {...state, errorMessage, error: true, loading: false};

        case A.LOG_OUT:
            return {...defaultState};

        default:
            return state;
    }
}
