import config from '../config/config';

export const boot = ({
                         name = null,
                         email = null,
                         hmac = null
                     }) => {
    const params = {
        app_id: config.intercom_app_id,
        created_at: new Date().getTime(), // Signup date as a Unix timestamp
    };
    if (name !== null) params.name = name;
    if (email !== null) params.email = email;
    if (hmac !== null) params.user_hash = hmac;

    if (Boolean(email) && !Boolean(hmac)) return; // When we try to boot Intercom with user email, but without hmac, Intercom api returns error 403 "identity_verification_missing_user_hash"
    window.Intercom('boot', params);
};

export const shutdown = () => {
    window.Intercom('shutdown');
};
