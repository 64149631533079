import firebase from 'firebase';
import 'firebase/functions';

firebase.initializeApp({
    apiKey: "AIzaSyBZf4_OyX3C7PYx-8c0q-WtJtl-SEuU5w8",
    authDomain: "https://trace-check-in.firebaseapp.com/",
    projectId: "trace-check-in",
    databaseURL: "https://trace-check-in.firebaseio.com"
});

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export const auth = firebase.auth();
export const functions = firebase.functions();
export const Timestamp = firebase.firestore.Timestamp;
