import {connect} from "react-redux";
import App from "./App";
import {getHmacByEmail} from "../../actions/getHmacByEmail";
import {setUser} from "../../actions/auth/setUser";
import {checkAndCreateCustomer} from "../../actions/stripe/checkAndCreateCustomer";

const mapStateToProps = ({
    user: {user},
    hmac: {hmac}
                         }) => ({
    user,
    hmac
});

export default connect(
    mapStateToProps,
    {getHmacByEmail, setUser, checkAndCreateCustomer}
)(App);
