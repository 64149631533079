import React, {Component} from "react";
import PropTypes from "prop-types";
import LogsView from "./Logs-view";
import {transformLogs, buildChartData} from "../../lib/logs";
import {routerTypes} from "../../types/routerTypes";
import AlertWarning from "../../components/AlertWarning";

class Logs extends Component {
    render() {
        const {
            logs,
            loading,
            error,
            errorMessage
        } = this.props;
        const props = {
            logs: transformLogs(logs),
            chartsDataHourly: buildChartData('hour')(logs),
            chartsDataDaily: buildChartData('day')(logs),
            chartsDataMonthly: buildChartData('month')(logs),
            isLoading: loading,
            isError: error,
            errorMessage: errorMessage
        };
        const noLogs = <strong>There are no scan results yet. Check your station is turned on.</strong>;
        return (logs.length > 0)
            ? <LogsView {...props} />
            : <AlertWarning message={noLogs}/>
    }
}

Logs.propTypes = {
    ...routerTypes,
    logs: PropTypes.array,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
};

export default Logs;
