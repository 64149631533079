import {connect} from 'react-redux';
import Cart from './Cart';

const mapStateToProps = ({
                             cart: {products}
                         }) => ({
    products
});

export default connect(
    mapStateToProps,
    null
)(Cart);
