import React from 'react';
import PropTypes from 'prop-types';
import PanelSelectStationView from "./PanelSelectStation-view";
import {routes as R} from "../../constants/routes";
import {routerTypes} from "../../types/routerTypes";

const PanelSelectStation = ({
                                history,
                                stationId,
                                setStationId,
                                getSettingsByStationId,
                                stations
                            }) => {
    const registerNewStation = () => history.push(R.REGISTRATION);

    const selectStation = id => {
        Promise.all([setStationId(id), getSettingsByStationId(id)])
    };

    return (
        <PanelSelectStationView
            stations={stations}
            selectStation={selectStation}
            current={stationId}
            createNew={registerNewStation}
        />
    );
};

PanelSelectStation.propTypes = {
    ...routerTypes,
    stationId: PropTypes.string,
    setStationId: PropTypes.func,
    getSettingsByStationId: PropTypes.func,
    stations: PropTypes.array
};

export default PanelSelectStation;
