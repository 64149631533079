import React, {Component} from "react";
import {compose} from 'redux';
import {connect} from "react-redux";
import AlertRegisterFirst from "../AlertRegisterFirst";
import {getStationIdByOwnerUid} from "../../actions/getStationIdByOwnerUid";

function withStation(WrappedComponent) {
    return class extends Component {
        render() {
            const {stationId, uid, getStationIdByOwnerUid, ...rest} = this.props;
            if (stationId === null) {
                getStationIdByOwnerUid(uid);
            }

            return (stationId !== null)
                ? <WrappedComponent {...rest}/>
                : <AlertRegisterFirst />;
        }
    }
}

const mapStateToProps = ({
                             station: {stationId},
                             user: {user: {uid}}
                         }) => {
    return {
        stationId,
        uid
    }
};

export default compose(connect(mapStateToProps, {getStationIdByOwnerUid}), withStation);
