import React from "react";
import {childrenTypes} from "../../types/childrenTypes";
import styles from "./styles.module.scss";

const ContainerPage = ({children}) => (
    <div className={styles.pageContainer}>
        <div className={styles.inner}>
            {children}
        </div>
    </div>
);

ContainerPage.propTypes = {
    ...childrenTypes
};

export default ContainerPage;
