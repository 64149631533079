import React from "react";
import {
    BrowserRouter,
    Switch,
    Route
} from "react-router-dom";
import {routes as R} from "../constants/routes";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CommonRoutes from "./CommonRoutes";
import LandingRoutes from "./LandingRoutes";
import Login from "../pages/Login";
import Support from "../pages/Support";
import Store from "../pages/Store";
import Cart from "../pages/Cart";
import Checkout from "../pages/Checkout";
import TermsOfService from "../pages/TermsOfService";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import OurProcess from "../pages/OurProcess";
import UserRoutes from "./UserRoutes";
import ContainerPage from "../components/ContainerPage";

const Router = () => (
    <BrowserRouter>
        <Header variant={'transparent-bg'} />
        <Switch>
            <Route exact path={R.HOME} component={LandingRoutes}/>
            <Route exact path={R.PROCESS} component={OurProcess}/>
            <ContainerPage>
                <Route exact path={R.LOGIN} component={Login}/>
                <Route exact path={R.HELP_CENTER} component={Support}/>
                <Route exact path={R.STORE} component={Store}/>
                <Route exact path={R.STORE_CART} component={Cart}/>
                <Route exact path={R.STORE_CHECKOUT} component={Checkout}/>
                <Route exact path={R.TERMS_OF_SERVICE}>
                    <TermsOfService/>
                </Route>
                <Route exact path={R.PRIVACY_POLICY}>
                    <PrivacyPolicy/>
                </Route>
                <Switch>
                    <UserRoutes />
                </Switch>
            </ContainerPage>
        </Switch>
        <Footer/>
    </BrowserRouter>
);

export default Router;
