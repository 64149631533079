import React, {Component} from "react";
import {compose} from 'redux';
import {connect} from "react-redux";
import AlertRegisterFirst from "../AlertRegisterFirst";
import Spinner from "react-bootstrap/Spinner";
import {getCustomerId} from "../../actions/stripe/getCustomerId";

function withStripeCustomer(WrappedComponent) {
    return class extends Component {
        render() {
            const {
                customerId,
                user,
                getCustomerId,
                ...rest} = this.props;
            if (customerId === null && user !== null) {
                const {email, uid} = user;
                getCustomerId({email, uid});
            }

            return (customerId === null && user !== null)
                ? <Spinner animation="border" variant="primary"/>
                : <WrappedComponent {...rest}/>;
        }
    }
}

const mapStateToProps = ({
                             stripeCustomer: {customerId},
                             user: {user}
                         }) => {
    return {
        customerId,
        user
    }
};

export default compose(connect(mapStateToProps, {getCustomerId}), withStripeCustomer);
