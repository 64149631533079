import {createStore, applyMiddleware, compose} from "redux";
import thunk from 'redux-thunk'
import {routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from "history";
import createRootReducer from "../reducers";
import {loadStoreFromCookies} from "../lib/store";
import {cartMW} from "./middleware/cart";

const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStore(initialState, history) {
    return createStore(
        createRootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(routerMiddleware(history), thunk, cartMW))
    );
}

const initialState = loadStoreFromCookies();
// const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);
window.store = store;
export {initialState, history, store}
