import {compose} from "redux";
import {withRouter} from "react-router-dom";
import withUser from "../../components/hoc/withUser";
import Registration from "./Registration-redux";
import withVerifiedUser from "../../components/hoc/withVerifiedUser";

export default compose(
    withRouter,
    withUser,
    withVerifiedUser
)(Registration);
