import React from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import {routes as R} from "../../constants/routes";

const Logout = ({logOut}) => {
    logOut();
    return <Redirect to={R.LOGIN} />;
};

Logout.propTypes = {
    logOut: PropTypes.func,
};

export default Logout;
