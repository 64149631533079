import React, {Component} from "react";
import {compose} from 'redux';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Spinner from 'react-bootstrap/Spinner';
import {getSettingsByStationId} from "../../actions/getSettingsByStationId";

function withSettings(WrappedComponent) {
    return class extends Component {
        static propTypes = {
            settings: PropTypes.object
        };

        render() {
            const {settings, stationId, getSettingsByStationId, ...rest} = this.props;
            if (settings === null) {
                getSettingsByStationId(stationId);
            }

            return (settings === null)
                ? <Spinner animation="border" variant="primary"/>
                : <WrappedComponent {...rest}/>;
        }
    }
}

const mapStateToProps = ({
                             settings: {settings},
                             station: {stationId}
                         }) => {
    return {
        settings,
        stationId
    }
};

export default compose(connect(mapStateToProps, {
    getSettingsByStationId
}), withSettings);

