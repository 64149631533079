import React, {Component} from "react";
import PropTypes from "prop-types";
import ChartLineView from "./ChartLine-view";

const defaultOptions = {
    series: [],
    options: {
        chart: {
            height: 350,
            type: 'line',
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
            enabled: true,
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        markers: {
            size: 1
        },
        xaxis: {
            type: "datetime"
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
        }
    }
};

class ChartLine extends Component {
    getOptions = () => {
        const {data} = this.props;
        const {options} = defaultOptions;
        return {
            series: data,
            options: {
                ...options
            }
        }
    };

    render() {
        const {title} = this.props;
        const chartProps = this.getOptions();
        return (
            <ChartLineView title={title} {...chartProps}/>
        )
    }
}

ChartLine.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
};

export default ChartLine;
