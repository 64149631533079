import {functions} from "../../lib/firebase";
import {functions as F} from "../../constants/functions";
import {actions as A} from "../../constants/actions";

export const getCustomerId = ({email, uid}) => {
    return (dispatch) => {
        dispatch({
            type: A.GET_CUSTOMER_ID
        });

        const getCustomerId = functions.httpsCallable(F.GET_CUSTOMER_ID);
        return getCustomerId({email, uid})
            .then(response => {
                const {id} = response.data;
                dispatch({
                    type: A.GET_CUSTOMER_ID_SUCCESS,
                    payload: {customerId: id}
                });

                return {id}
            })
            .catch((err) => {
                dispatch({
                    type: A.GET_CUSTOMER_ID_FAILURE,
                    payload: {errorMessage: err.message}
                })
            });
    };
};
