import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {dashboardFilters as F} from "../../../constants/dashboardFilters";
import styles from "./styles.module.scss";

const Filter = ({
                        filter,
                        onChange
                    }) => (
    <div className={styles.filter}>
        <ButtonGroup
            size="lg"
            toggle={true}
        >
            <Button
                variant="secondary"
                className={(filter === F.all)? "active" : ""}
                onClick={() => onChange(F.all)}
            >
                All
            </Button>
            <Button
                variant="secondary"
                className={(filter === F.range)? "active" : ""}
                onClick={() => onChange(F.range)}
            >
                Range
            </Button>
        </ButtonGroup>
    </div>
);

Filter.propTypes = {
    filter: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default Filter;
