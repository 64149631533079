import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import {useTable, useSortBy} from 'react-table';
import styles from "./styles.module.scss";

const columnsList = [
    {
        Header: 'Date & time',
        accessor: 'timestamp',
    },
    {
        Header: 'Active',
        accessor: 'active',
    },
    {
        Header: 'Rssi',
        accessor: 'rssi',
    },
    {
        Header: 'Address',
        accessor: 'address',
    },
    {
        Header: 'Uuid',
        accessor: 'uuid',
    },
];

const TableLogs = ({logs}) => {
    const columns = React.useMemo(() => columnsList, []);
    const data = React.useMemo(() => logs, [logs]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy
    );

    return (
        <>
            <Table
                striped
                bordered
                hover
                   variant="primary"
                {...getTableProps()}
                className={styles.table}
            >
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr className={styles.header} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} className={styles.row} key={i}>
                            {row.cells.map(cell => {
                                return (
                                    <td className={styles[cell.column.id]} {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })
                }
                </tbody>
            </Table>
        </>
    )
};

TableLogs.propTypes = {
    logs: PropTypes.arrayOf(PropTypes.object)
};

export default TableLogs;
