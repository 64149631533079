import React from "react";
import {NavLink} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import {routes as R} from "../../constants/routes";
import styles from "./styles.module.scss";

const title = 'You must log-in first';
const line1 = 'Once logged in, you will have full access to your device details.';
const line2 = 'Please log-in using your email and password.';

const AlertLoginFirst = () => (
    <Alert variant="warning" className={styles.alert}>
        <Alert.Heading>{title}</Alert.Heading>
        <p>{line1}</p>
        <p>{line2}</p>
        <Button variant="primary" className={"ml-auto mr-auto d-block"}>
            <NavLink
                to={R.LOGIN}
                className={`nav-link ${styles.btn}`}
            >
                Log in
            </NavLink>
        </Button>
    </Alert>
);
export default AlertLoginFirst;
