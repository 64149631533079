import React from 'react';
import {NavLink} from "react-router-dom";
import {routes as R} from "../../../constants/routes";
import arrow from '../../../assets/landing/arrow.svg';
import img from '../../../assets/landing/img-section-2.jpg';
import styles from './styles.module.scss';

const headerAbout = 'Trace Check-In';
const textsAbout = [
    'Trace Check-In is a solution designed to help your organization leverage existing contact tracing solutions and ensure their effectiveness in the workplace, creating a safer return to work.',
    'Along with other protocols like masks and social distancing - digital contact tracing is proving to be a critical part of keeping our teams and communities safe.',
    'Trace Check-In helps your organization leverage existing contact tracing solutions, creating a safer return to work.'
];
const labelGetStarted = 'Get started';

const About = () => (
    <section className={styles.aboutContainer}>
        <div className={styles.col}>
            <img src={img} alt="img"/>
        </div>
        <div className={styles.col}>
            <h2>{headerAbout}</h2>
            <div>{textsAbout.map((text, ind) => <p key={ind}>{text}</p>)}</div>
            <div className={styles.aboutGetStarted}>
                <NavLink to={R.STORE}>{labelGetStarted}</NavLink>
                <img src={arrow} alt="arrow"/>
            </div>
        </div>
    </section>
);

export default About;
