import React from 'react';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {routerTypes} from '../../types/routerTypes';
import hand from '../../assets/img-hand.png';
import styles from './styles.module.scss';

const linkStart = 'https://intercom.help/tracecheckin/collections/2646328';
const linkSetup = 'https://intercom.help/tracecheckin/collections/2684253';
const linkInstructions = 'https://intercom.help/tracecheckin/collections/2646337';
const linkAdjust = 'https://intercom.help/tracecheckin/collections/2646342';
const linkMaintenance = 'https://intercom.help/tracecheckin/collections/2646365';

const Support = props => (
    <div className={styles.supportContainer}>
        <div className={styles.welcomeContainer}>
            <img src={hand} alt="hand" className={styles.hand}/>
            <div className={styles.about}>
                <a href={linkStart} className={`${styles.title} ${styles.link} ${styles.bigText}`}>
                    Welcome to Trace Check-In: First Steps
                </a>
                <p className={styles.text}>
                    We’re so glad you chose Trace Check-In to support your safer
                    return to work. If you just received your Trace Check-In
                    device, click here to walk through the full set-up
                    experience.
                </p>
                <a href={linkStart} className={styles.buttonStart}>
                    Start Here
                </a>
            </div>
        </div>

        <div className={styles.linksContainer}>
            <Container>
                <Row>
                    <Col sm>
                        <div className={styles.links}>
                            <h2 className={`${styles.bigText} ${styles.linkTitle}`}>
                                Getting Set-Up
                            </h2>
                            <a href={linkSetup} className={styles.link}>
                                Set-up, Connect, & Register
                            </a>
                            <a href={linkInstructions} className={styles.link}>
                                Installation Instructions
                            </a>
                        </div>

                    </Col>
                    <Col sm>
                        <div className={styles.links}>
                            <h2 className={`${styles.bigText} ${styles.linkTitle}`}>
                                Ongoing Support
                            </h2>
                            <a href={linkAdjust} className={styles.link}>
                                Adjust Trace Check-In app settings
                            </a>
                            <a href={linkMaintenance} className={styles.link}>
                                Maintenance
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    </div>
);

Support.propTypes = {
    ...routerTypes
};

export default Support;
