import React, {Component} from "react";
import {compose} from 'redux';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Spinner from 'react-bootstrap/Spinner';
import {getProducts} from "../../actions/store/getProducts";

function withScanResults(WrappedComponent) {
    return class extends Component {
        static propTypes = {
            products: PropTypes.array
        };

        render() {
            const {products, getProducts, ...rest} = this.props;
            if (products === null) {
                getProducts();
            }
            
            return (products === null)
                ? <Spinner animation="border" variant="primary"/>
                : <WrappedComponent {...rest}/>;
        }
    }
}

const mapStateToProps = ({
                             products: {products},
                         }) => {
    return {
        products,
    }
};

export default compose(connect(mapStateToProps, {
    getProducts
}), withScanResults);

