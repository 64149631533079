import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import withUser from "../../components/hoc/withUser";
import withStations from "../../components/hoc/withStations";
import PanelSelectStation from './PanelSelectStation-redux';

export default compose(
    withRouter,
    withUser,
    withStations,
)(PanelSelectStation);
