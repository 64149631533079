import {compose} from "redux";
import {withRouter} from "react-router-dom";
import withUser from "../../components/hoc/withUser";
import withStation from "../../components/hoc/withStation";
import withSettings from "../../components/hoc/withSettings";
import PanelStationInfo from "./PanelStationInfo-redux";
import withVerifiedUser from "../../components/hoc/withVerifiedUser";

export default compose(
    withRouter,
    withUser,
    withVerifiedUser,
    withStation,
    withSettings
)(PanelStationInfo);
