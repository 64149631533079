import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    Product,
    CheckoutButton,
} from "react-shopping-cart";
import {NavLink} from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import {routes as R} from "../../constants/routes";
import AlertLoginFirst from "../../components/AlertLoginFirst";
import CartIcon from "../../components/CartIcon";
// import "bootstrap/dist/css/bootstrap.css";
import "animate.css/animate.min.css";
import "font-awesome/css/font-awesome.min.css";
import styles from './styles.module.scss';

const catalogTitle = 'Our products';

const CatalogItem = ({
                         id,
                         name,
                         active,
                         imageSrc,
                         selectedId,
                         selectProduct,
                     }) => (
    <div
        className={classnames(styles.catalogItem, {
            [styles.selected]: id === selectedId,
            [styles.disabled]: !active
        })}
        title={name}
        onClick={() => {
            active && selectProduct(id)
        }}
    >
        <img src={imageSrc[0]} alt={name}/>
    </div>
);

CatalogItem.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    imageSrc: PropTypes.array.isRequired,
    selectedId: PropTypes.string.isRequired,
    selectProduct: PropTypes.func.isRequired
};

const ProductItem = ({product, checkoutButton, userIsLogged}) => (
    (product.requireUser && !userIsLogged)
        ? (
            <AlertLoginFirst/>
        )
        : (
            <Product
                {...product}
                checkoutButton={checkoutButton}
                descriptionNode={(
                    <Description
                        {...product}
                    />
                )}
            />
        )
);

ProductItem.propTypes = {
    product: PropTypes.object.isRequired,
};

const Description = ({name, description, imageSrc, freeShipping}) => (
    <div className={"w-100"}>
        <h2 className={"text-center"}>{name}</h2>
        <Carousel className={"w-75 ml-auto mr-auto mb-2"}>
            {imageSrc.map((image, index) => (
                <Carousel.Item key={index}>
                    <div
                        className={styles.carouselImg}
                        style={{backgroundImage: `url("${image}")`}}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
        <p className={styles.description}>{description}</p>
        {freeShipping && (
            <p className={styles.freeShipping}>Free shipping</p>
        )}
    </div>
);

Description.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    imageSrc: PropTypes.array.isRequired,
    freeShipping: PropTypes.bool,
};

const StoreView = ({
                       products,
                       selected,
                       selectProduct,
                       userIsLogged,
                   }) => (
    <div className="container mb-5 position-relative">
        <h1>{catalogTitle}</h1>
        <div className={styles.catalog}>
            {products.map((product, index) => (
                <CatalogItem
                    key={index}
                    {...product}
                    selectedId={selected.id}
                    selectProduct={selectProduct}
                />
            ))}
        </div>
        <ProductItem
            product={selected}
            userIsLogged={userIsLogged}
        />
        <div className={styles.cartIconBox}>
            <CartIcon/>
        </div>
    </div>
);

StoreView.propTypes = {
    products: PropTypes.array.isRequired,
    selected: PropTypes.object.isRequired,
    selectProduct: PropTypes.func.isRequired,
    userIsLogged: PropTypes.bool
};

export default StoreView;
