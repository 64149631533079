import {functions} from "../lib/firebase";
import {functions as F} from "../constants/functions";
import {actions as A} from "../constants/actions";
import {getStationIdShortHash} from "../lib/hash";

export const checkStationId = stationId => {
    return (dispatch) => {
        dispatch({
            type: A.CHECK_STATION_ID
        });
        const getStationIdByHash = functions.httpsCallable(F.CHECK_STATION_ID);
        return getStationIdByHash({stationId})
            .then(response => {
                const {stationId} = response.data;
                const hash = getStationIdShortHash(stationId);
                dispatch({
                    type: A.CHECK_STATION_ID_SUCCESS,
                    payload: {stationId, hash}
                })
            })
            .catch((err) => {
                dispatch({
                    type: A.CHECK_STATION_ID_FAILURE,
                    payload: {errorMessage: err.message}
                })
            });
    };
};
