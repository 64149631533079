import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import PanelSelectStation
    from '../../components/PanelSelectStation';
import PanelStationInfo
    from '../../components/PanelStationInfo';
import styles from './styles.module.scss';

const AccountView = ({name, email, onLogOut, onCustomerPortal}) => (
    <>
        <Card className={`w-100 mb-3 ${styles.userInfo}`}>
            <Card.Header>
                <h2>User info</h2>
            </Card.Header>
            <Card.Body>
                <Container className={'container-xl p-0 m-0'}>
                    <Row md>
                        <Col>
                            Name:
                        </Col>
                        <Col md={4}>
                            {name}
                        </Col>
                    </Row>
                    <Row md>
                        <Col>
                            Email:
                        </Col>
                        <Col md={4}>
                            {email}
                        </Col>
                    </Row>
                    <Row md>
                        <Col>
                            Manage your billings:
                        </Col>
                        <Col md={4}>
                            <Button
                                type="button"
                                variant="primary"
                                onClick={onCustomerPortal}
                            >
                                Customer Panel
                            </Button>
                        </Col>
                    </Row>
                    <Row md>
                        <Col>
                            <Button
                                variant="outline-primary"
                                onClick={onLogOut}
                            >
                                Log out
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
        <Card className={"w-100 mb-3"}>
            <Card.Header>
                <h2>Stations</h2>
            </Card.Header>
            <Card.Body>
                <PanelSelectStation />
            </Card.Body>
        </Card>
        <PanelStationInfo />
    </>
);

AccountView.propTypes = {
    name: PropTypes.node,
    email: PropTypes.node,
    onLogOut: PropTypes.func,
    onCustomerPortal: PropTypes.func
};

export default AccountView;
