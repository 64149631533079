export const dashboardFilters = {
    all: "all",
    range: "range"
};

export const dashboardGraphs = {
    hours: "hours",
    days: "days",
    months: "months"
};
