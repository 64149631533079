import {actions as A} from "../../constants/actions";

export const setAuthError = err => {
    return (dispatch) => {
        return dispatch({
            type: A.AUTH_WITH_FIREBASE_FAILURE,
            payload: err
        })
    };
};
