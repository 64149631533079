import React, {Component} from "react";
import PropTypes from "prop-types";
import ChartPieView from "./ChartPie-View";

const defaultOptions = {
    series: [],
    options: {
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: [],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }
};

class ChartPie extends Component {
    getOptions = () => {
        const {series, labels, opts} = this.props;
        const {options} = defaultOptions;
        return {
            series,
            options: {
                ...options,
                ...opts,
                labels
            }
        }
    };

    render() {
        const {title} = this.props;
        const chartProps = this.getOptions();
        return (
            <ChartPieView title={title} {...chartProps}/>
        )
    }
}

ChartPie.propTypes = {
    title: PropTypes.string.isRequired,
    series: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired
};

export default ChartPie;
