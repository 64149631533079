import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import withStripeCustomer from '../../components/hoc/withStripeCustomer';
import withProducts from '../../components/hoc/withProducts';
import Checkout from './Checkout-redux';

export default compose(
    withRouter,
    withProducts,
    withStripeCustomer
)(Checkout);
