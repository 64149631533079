import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Alert from "react-bootstrap/Alert";

const AlertError = ({message}) => (
    <Alert variant="danger">
        <Alert.Heading>Oh no! You got an error!</Alert.Heading>
        {message}
    </Alert>
);

AlertError.propTypes = {
    message: PropTypes.string.isRequired
};

export default AlertError;
