import React from 'react';
import {
    Route
} from "react-router-dom";
import Landing from "../../pages/Landing";
import OurProcess from "../../pages/OurProcess";
import {routes as R} from "../../constants/routes";

const LandingRoutes = () => (
    <>
        <Route exact path={R.HOME} component={Landing}/>
        <Route exact path={R.PROCESS} component={OurProcess}/>
    </>
);

export default LandingRoutes;
