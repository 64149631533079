import React, {Component} from "react";
import PropTypes from "prop-types";
import FormStationSettingsView from "./FormStationSettings-view";
import {defaultSettings} from "../../constants/defaultSettings";

const defaultState = {
    ...defaultSettings,
    canSave: true
};

class FormStationSettings extends Component {
    state = defaultState;

    componentDidMount() {
        this.updateSettings();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const equalSettings = JSON.stringify(prevProps.settings) === JSON.stringify(this.props.settings);
        if (!equalSettings) this.updateSettings();
    }

    updateSettings = () => {
        const {settings} = this.props;
        this.setState({
            ...settings,
            canSave: false
        });
    };

    handleInputChange = key => event => {
        const value = (typeof this.state[key] === 'boolean')
            ? event.target.checked
            : event.target.value;
        this.setState({
            [key]: value,
            canSave: true
        })
    };

    handleAddFilter = filter => {
        const {scanFilters} = this.state;
        this.setState({
            scanFilters: [
                ...scanFilters,
                `${filter}-xxxx-xxxx-xxxx-xxxxxxxxxxxx`
            ],
            canSave: true
        })
    };

    handleRemoveFilter = removingFilter => {
        const {scanFilters} = this.state;
        const filteredFilters = scanFilters.filter(filter => filter !== removingFilter);
        this.setState({
            scanFilters: filteredFilters,
            canSave: true
        });
    };

    reset = () => {
        this.updateSettings();
    };

    save = () => {
        const {
            setSettingsForStationId,
            getSettingsByStationId,
            stationId
        } = this.props;
        const {canSave, ...rest} = this.state;
        const settings = this.normalizeSettings(rest);

        setSettingsForStationId(stationId, settings)
            .then(() => {
                return getSettingsByStationId(stationId);
            })
            .then(() => {
                this.updateSettings();
            })
    };

    normalizeSettings = rawSettings => {
        const settings = {};
        for (let key in rawSettings) {
            if (rawSettings.hasOwnProperty(key)
                && typeof rawSettings[key] === 'string'
                && !isNaN(rawSettings[key])) {
                    settings[key] = Number(rawSettings[key]);
            } else {
                settings[key] = rawSettings[key];
            }
        }
        return settings;
    };

    render() {
        const {loading, error, errorMessage} = this.props;
        return (
            <FormStationSettingsView
                {...this.state}
                onRssiChange={this.handleInputChange('activeRssi')}
                onHighRssiChange={this.handleInputChange('highRssi')}
                onHighRssiOffsetChange={this.handleInputChange('highRssiOffset')}
                onPeriodChange={this.handleInputChange('loggingPeriod')}
                onFaceDetectionChange={this.handleInputChange('isFaceDetectionEnabled')}
                onAddFilter={this.handleAddFilter}
                onRemoveFilter={this.handleRemoveFilter}
                onReset={this.reset}
                onSave={this.save}
                loading={loading}
                error={error}
                errorMessage={errorMessage}
            />
        )
    }
}

FormStationSettings.propTypes = {
    settings: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    stationId: PropTypes.string,
    setSettingsForStationId: PropTypes.func,
    getSettingsByStationId: PropTypes.func
};

export default FormStationSettings;
