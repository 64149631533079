import {actions as A} from "../constants/actions";
import {cookies as K} from "../constants/cookies";
import {setCookie, clearAllCookies} from "../lib/utils/cookies";

const defaultState = {
    user: null,
    loading: false,
    error: false,
    errorMessage: ''
};

export default (state = defaultState, {type, payload}) => {
    switch(type) {
        case A.AUTH_WITH_FIREBASE:
            return {...state, loading: true};

        case A.AUTH_WITH_FIREBASE_SUCCESS:
            const {user} = payload;
            setCookie(K.user, JSON.stringify(user));
            return {...state, user, loading: false};

        case A.AUTH_WITH_FIREBASE_FAILURE:
            const {errorMessage} = payload;
            return {...state, errorMessage, error: true, loading: false};

        case A.SET_EMAIL_VERIFIED:
            const {user: currentUser} = state;
            const {emailVerified} = payload;
            if (currentUser === null) return {...state};

            return {...state, user: {...currentUser, emailVerified}};

        case A.SET_USER:
            return {...state, user: payload.user};

        case A.LOG_OUT:
            clearAllCookies();
            return {...defaultState};

        default:
            return state;
    }
}
